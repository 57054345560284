import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import LiabilityWaiver from './LiabilityWaiver';
import TermsOfUse from './TermsOfUse';
import AppExplanation from './AppExplanation';
import UserProfile from './UserProfile';
import DominantQuestions from './DominantQuestions';
import SubmissiveQuestions from './SubmissiveQuestions';
import { axiosInstance } from './auth';
import WizardPortal from './WizardPortal';

const steps = [
  'Liability Waiver',
  'Terms of Use',
  'App Explanation',
  'User Profile',
  'Role-Specific Questions'
];

const FirstLoginWizard = ({ onComplete }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [wizardData, setWizardData] = useState({
    liabilityWaiver: false,
    termsOfUse: false,
    userProfile: {},
    dominantSettings: {},
    submissiveSettings: {},
  });
  const [partnerSettings, setPartnerSettings] = useState(null);

  useEffect(() => {
    const savedState = localStorage.getItem('wizardState');
    if (savedState) {
      setWizardData(JSON.parse(savedState));
      setActiveStep(JSON.parse(savedState).lastCompletedStep + 1);
    }
  }, []);

  useEffect(() => {
    if (wizardData.userProfile.role === 'submissive' || 
        wizardData.userProfile.role === 'switch (mostly submissive)') {
      fetchPartnerSettings();
    }
  }, [wizardData.userProfile.role]);

  const fetchPartnerSettings = async () => {
    try {
      const response = await axiosInstance.get('/settings');
      setPartnerSettings(response.data);
    } catch (error) {
      console.error('Failed to fetch partner settings:', error);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep + 1;
      saveWizardState(newStep - 1);
      return newStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const saveWizardState = (step) => {
    localStorage.setItem('wizardState', JSON.stringify({ ...wizardData, lastCompletedStep: step }));
  };

  const handleComplete = async () => {
    try {
      await axiosInstance.post('/complete-wizard', wizardData);
      localStorage.removeItem('wizardState');
      onComplete();
    } catch (error) {
      console.error('Failed to complete wizard:', error);
    }
  };

  const getStepContent = (step) => {
    console.log('Current step:', step);
    console.log('Current wizardData:', wizardData);
    
    switch (step) {
      case 0:
        return <AppExplanation />;
      case 1:
        return <TermsOfUse onAgree={(agreed) => setWizardData({ ...wizardData, termsOfUse: agreed })} />;
      case 2:
        return <LiabilityWaiver onAgree={(agreed) => setWizardData({ ...wizardData, liabilityWaiver: agreed })} />;
      case 3:
        return <UserProfile onComplete={(profile) => setWizardData({ ...wizardData, userProfile: profile })} />;
      case 4:
        console.log('Role:', wizardData.userProfile.user_type); // Changed from .role to .user_type
        if (wizardData.userProfile.user_type === 'dominant' || 
            wizardData.userProfile.user_type === 'switch (mostly dominant)') {
          console.log('Rendering DominantQuestions');
          return (
            <DominantQuestions 
              onComplete={(settings) => {
                console.log('DominantQuestions completed with settings:', settings);
                setWizardData({ ...wizardData, dominantSettings: settings });
                handleComplete();
              }} 
            />
          );
        } else if (wizardData.userProfile.user_type === 'submissive' || 
                   wizardData.userProfile.user_type === 'switch (mostly submissive)') {
          if (!partnerSettings || !partnerSettings.chastityEnabled) {
            return <Typography>Waiting for your Dominant to set up the chastity settings.</Typography>;
          }
          return (
            <SubmissiveQuestions 
              onComplete={(settings) => {
                setWizardData({ ...wizardData, submissiveSettings: settings });
                handleComplete();
              }} 
              partnerSettings={partnerSettings} 
            />
          );
        }
        return null;
      default:
        return 'Unknown step';
    }
  };

  return (
    <WizardPortal>
      <Box sx={{ 
        width: '100%', 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        p: 2, 
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: 1300 
      }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ flex: 1, overflow: 'auto', my: 2 }}>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
              {getStepContent(activeStep)}
            </React.Fragment>
          )}
        </Box>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          position: 'sticky', 
          bottom: 0, 
          bgcolor: 'background.paper', 
          py: 2 
        }}>
          <Button
            variant="contained"
            color="primary"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={activeStep === steps.length - 1 ? handleComplete : handleNext}
            disabled={activeStep === 4 && wizardData.userProfile.role === 'submissive' && (!partnerSettings || !partnerSettings.chastityEnabled)}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Box>
    </WizardPortal>
  );
};

export default FirstLoginWizard;