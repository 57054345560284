import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import WheelOfPrizes from './WheelOfPrizes';

const SubmissiveQuestions = ({ onComplete, partnerSettings }) => {
  const [chastityPeriod, setChastityPeriod] = useState(null);

  const handleSpinComplete = (result) => {
    setChastityPeriod(result);
  };

  const handleSubmit = () => {
    onComplete({ chastityPeriod });
  };

  const chastityOptions = [
    '1 day', '1 week', '2 weeks', '3 weeks', '1 month', '2 months', '3 months',
    '6 months', '1 year', '2 years', '5 years', 'Permanent'
  ];

  if (partnerSettings.isPermanentChastity) {
    return (
      <Box>
        <Typography variant="h6">Submissive Settings</Typography>
        <Typography paragraph>
          Your Dominant has set your chastity period to be permanent.
        </Typography>
        <Button onClick={() => onComplete({ chastityPeriod: 'Permanent' })}>
          Accept and Continue
        </Button>
      </Box>
    );
  }

  if (partnerSettings.chastityPeriod && partnerSettings.chastityPeriod !== 'spin') {
    return (
      <Box>
        <Typography variant="h6">Submissive Settings</Typography>
        <Typography paragraph>
          Your Dominant has set your chastity period to: {partnerSettings.chastityPeriod}
        </Typography>
        <Button onClick={() => onComplete({ chastityPeriod: partnerSettings.chastityPeriod })}>
          Accept and Continue
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6">Submissive Settings</Typography>
      <Typography paragraph>
        Your Dominant has chosen for you to spin the wheel to determine your chastity period. This could be really bad!
      </Typography>
      <WheelOfPrizes segments={chastityOptions} onFinished={handleSpinComplete} />
      {chastityPeriod && (
        <Typography paragraph>
          Your chastity period has been set to: {chastityPeriod}
        </Typography>
      )}
      <Button onClick={handleSubmit} disabled={!chastityPeriod}>
        Accept and Continue
      </Button>
    </Box>
  );
};

export default SubmissiveQuestions;