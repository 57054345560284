import React, { useState, useEffect } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Accordion, AccordionSummary, AccordionDetails, 
  Select, MenuItem, Snackbar, CircularProgress, Card, CardContent,
  Box, Chip, Tooltip, List
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const ratingOptions = ['select', 'hard no', 'if forced', 'maybe', 'yes', 'please'];
const dominantRatingOptions = ['select', 'hard no', 'if you really want it', 'maybe', 'yes', 'very much yes'];

const ratingEmojis = {
  'select': '🤔',
  'hard no': '🚫',
  'if forced': '😬',
  'maybe': '🤷',
  'yes': '😊',
  'please': '😍',
  'if you really want it': '🤨',
  'very much yes': '🥰'
};

const Kinks = ({ role }) => {
  const theme = useTheme();
  const [kinks, setKinks] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filterOption, setFilterOption] = useState('');

  useEffect(() => {
    fetchKinks();
  }, []);

  const fetchKinks = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/kinks`);
      setKinks(response.data);
    } catch (error) {
      setErrorMessage('Failed to fetch kinks. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRatingChange = async (kinkId, rating) => {
    console.log(`Attempting to update kink ${kinkId} with rating ${rating}`);
    try {
      const payload = { id: kinkId, rating: rating };
      console.log('Sending payload:', payload);
      const response = await axiosInstance.put(`/kinks`, payload);
      console.log('Response from server:', response);
      if (response.status === 200) {
        const updatedKink = response.data;
        console.log('Updated kink:', updatedKink);
        setKinks(prevKinks => prevKinks.map(kink => 
          kink.id === kinkId ? { ...kink, [role === 'submissive' ? 'submissive_rating' : 'dominant_rating']: rating } : kink
        ));
      } else {
        throw new Error('Failed to update kink rating');
      }
    } catch (error) {
      console.error('Error updating kink rating:', error);
      setErrorMessage('Failed to update kink rating. Please try again.');
    }
  };

const renderRatingSelect = (kink) => (
  <Select
    value={role === 'submissive' ? kink.submissive_rating : kink.dominant_rating || 'select'}
    onChange={(e) => handleRatingChange(kink.id, e.target.value)}
    sx={{ 
      minWidth: 120, 
      color: theme.palette.mode === 'dark' && determineCardColor(kink) === 'inherit' ? 'white' : 'black',
      '& .MuiSelect-select': {
        py: 0.5, // Reduce padding top and bottom
      },
    }}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 300,
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
        },
      },
    }}
  >
    {(role === 'submissive' ? ratingOptions : dominantRatingOptions).map((option) => (
      <MenuItem 
        key={option} 
        value={option} 
        sx={{ 
          py: 0.5,
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.16)' : 'rgba(0, 0, 0, 0.08)',
            '&:hover': {
              backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.24)' : 'rgba(0, 0, 0, 0.12)',
            },
          },
        }}
      >
        {ratingEmojis[option]} {option}
      </MenuItem>
    ))}
  </Select>
);

const renderPartnerRating = (kink) => {
  const rating = role === 'submissive' ? kink.dominant_rating : kink.submissive_rating;
  return (
    <Tooltip title={rating || 'Not rated'} arrow>
      <Chip
        label={ratingEmojis[rating] || '🤔'}
        color="primary"
        variant="outlined"
        size="small" // Make the chip smaller
        sx={{ 
          ml: 1, // Reduce margin left
          color: theme.palette.mode === 'dark' && determineCardColor(kink) === 'inherit' ? 'white' : 'black',
        }}
      />
    </Tooltip>
  );
};

const determineCardColor = (kink) => {
  const { dominant_rating, submissive_rating } = kink;

  // Green matches
  if (
    (submissive_rating === 'yes' && dominant_rating === 'yes') ||
    (submissive_rating === 'yes' && dominant_rating === 'very much yes') ||
    (submissive_rating === 'please' && dominant_rating === 'very much yes') ||
    (submissive_rating === 'please' && dominant_rating === 'yes')
  ) {
    return '#c8e6c9'; // Light green
  }

  // Orange matches
  if (
    (submissive_rating === 'maybe' && ['yes', 'very much yes', 'please'].includes(dominant_rating)) ||
    (submissive_rating === 'if forced' && dominant_rating === 'very much yes') ||
    (submissive_rating === 'please' && dominant_rating === 'if you really want it')
  ) {
    return '#ffe0b2'; // Light orange
  }

  // Red matches
  if (
    (submissive_rating === 'hard no' || dominant_rating === 'hard no')
  ) {
    return '#ffcdd2'; // Light red
  }

  // Inherit (due to color scheme) for incomplete or unmatched combinations
  return 'inherit';
};

  const renderKinkItem = (kink) => (
    <Card key={kink.id} sx={{ 
      mb: 1, // Reduce margin bottom
      backgroundColor: determineCardColor(kink),
    }}>
      <CardContent sx={{ 
        py: 1, // Reduce padding top and bottom
        '&:last-child': { pb: 1 }, // Ensure the last child also has reduced padding
      }}>
        <Typography 
          variant="subtitle1" // Change from h6 to subtitle1 for slightly smaller text
          sx={{ 
            color: theme.palette.mode === 'dark' && determineCardColor(kink) === 'inherit' ? 'white' : 'black',
            mb: 0.5, // Reduce margin bottom
          }}
        >
          {kink.name}
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            color: theme.palette.mode === 'dark' && determineCardColor(kink) === 'inherit' ? 'white' : 'black',
            mb: 1, // Reduce margin bottom
          }}
        >
          {kink.description}
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mt: 0.5, // Add a small margin top
        }}>
          {renderRatingSelect(kink)}
          {renderPartnerRating(kink)}
        </Box>
      </CardContent>
    </Card>
  );
  
  const groupedKinks = kinks.reduce((acc, kink) => {
    if (!acc[kink.category]) {
      acc[kink.category] = [];
    }
    acc[kink.category].push(kink);
    return acc;
  }, {});

  const filteredGroupedKinks = Object.entries(groupedKinks).reduce((acc, [category, categoryKinks]) => {
    const filteredKinks = categoryKinks.filter((kink) => {
      if (filterOption === '') return true;
      const cardColor = determineCardColor(kink);
      return cardColor === filterOption;
    });
    if (filteredKinks.length > 0) {
      acc[category] = filteredKinks;
    }
    return acc;
  }, {});

  if (isLoading) return <CircularProgress />;

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Kinks</Typography>
      <Select 
        value={filterOption} 
        onChange={(e) => setFilterOption(e.target.value)} 
        displayEmpty
        fullWidth
        sx={{ mb: 2 }}
      >
        <MenuItem value="">Filter by alignment</MenuItem>
        <MenuItem value="#c8e6c9">Most Aligned</MenuItem>
        <MenuItem value="#ffe0b2">Partially Aligned</MenuItem>
        <MenuItem value="#ffcdd2">Limits</MenuItem>
        <MenuItem value="#f5f5f5">Not Filled</MenuItem>
      </Select>
      {Object.entries(filteredGroupedKinks).map(([category, categoryKinks]) => (
        <Accordion key={category} disableGutters>
          <AccordionSummary 
            expandIcon={<ExpandMore />}
            sx={{ minHeight: 48, '& .MuiAccordionSummary-content': { my: 0 } }}
          >
            <Typography variant="subtitle1">{category}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ py: 1 }}>
            <List sx={{ py: 0 }}>
              {categoryKinks.map(renderKinkItem)}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
    </Container>
  );
};

export default Kinks;
