import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './MobileCalendar.css';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveCalendar } from '@nivo/calendar';
import { 
  Box, 
  Typography, 
  ToggleButtonGroup, 
  ToggleButton, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  useTheme, 
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Chip,
  IconButton,
  Snackbar,
  Alert,
  Paper,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Stack,
  Menu,
  MenuItem,
  Tooltip,
  Fade,
  TextField
} from '@mui/material';
import {
  Assignment as TaskIcon,
  Warning as PunishmentIcon,
  Stars as RewardIcon,
  CheckCircle as CompletedIcon,
  Schedule as PendingIcon,
  Error as IncompleteIcon,
  Lock as LockIcon,
  PlayArrow,
  Edit,
  Delete,
  CalendarToday,
  DateRange,
  Event,
  EventNote,
  Timeline,
  TrendingUp,
  Category,
  AccessTime,
  Description,
  Star,
  StarBorder,
  NavigateBefore,
  NavigateNext,
  ExpandMore,
  Today,
  CalendarMonth,
  ViewDay,
  ViewWeek,
  ArrowDropDown
} from '@mui/icons-material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DatePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { axiosInstance } from './auth';
import { CompletionForm } from './tasks';
import { PunishmentForm } from './punishments';
import { RewardForm } from './rewards';

const localizer = momentLocalizer(moment);

const CompletionHistory = ({ data }) => {
  const theme = useTheme();
  
  const lineData = [
    {
      id: 'completions',
      data: data.map(d => ({
        x: moment(d.date).format('YYYY-MM-DD'),
        y: d.status === 'completed' ? 1 : 0
      }))
    }
  ];

  const calendarData = data.map(d => ({
    day: moment(d.date).format('YYYY-MM-DD'),
    value: d.status === 'completed' ? 1 : 0
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader 
            title="Completion Trend" 
            titleTypography={{ variant: 'h6' }}
            avatar={<TrendingUp />}
          />
          <CardContent style={{ height: 200 }}>
            <ResponsiveLine
              data={lineData}
              margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
              xScale={{ type: 'time', format: '%Y-%m-%d' }}
              yScale={{ type: 'linear', min: 0, max: 1 }}
              axisBottom={{
                format: '%b %d',
                tickRotation: -45
              }}
              curve="monotoneX"
              enablePoints={true}
              pointSize={8}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fill: theme.palette.text.primary
                    }
                  }
                },
                grid: {
                  line: {
                    stroke: theme.palette.divider
                  }
                },
                tooltip: {
                  container: {
                    background: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    fontSize: '12px',
                    borderRadius: '4px',
                    boxShadow: theme.shadows[3]
                  }
                }
              }}
              tooltip={({ point }) => (
                <Box sx={{ p: 1 }}>
                  <Typography variant="caption">
                    {moment(point.data.x).format('MMM D, YYYY')}:
                    {point.data.y === 1 ? ' Completed' : ' Not Completed'}
                  </Typography>
                </Box>
              )}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader 
            title="Completion Calendar" 
            titleTypography={{ variant: 'h6' }}
            avatar={<CalendarMonth />}
          />
          <CardContent style={{ height: 200 }}>
            <ResponsiveCalendar
              data={calendarData}
              from={moment().subtract(3, 'months').format('YYYY-MM-DD')}
              to={moment().format('YYYY-MM-DD')}
              emptyColor={theme.palette.background.paper}
              colors={[
                theme.palette.primary.light,
                theme.palette.primary.main,
                theme.palette.primary.dark
              ]}
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              yearSpacing={40}
              monthBorderColor={theme.palette.divider}
              dayBorderWidth={2}
              dayBorderColor={theme.palette.divider}
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'row',
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: 'right-to-left'
                }
              ]}
              tooltip={({ day, value }) => (
                <Box sx={{ 
                  p: 1, 
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  boxShadow: 1
                }}>
                  <Typography variant="caption">
                    {moment(day).format('MMM D, YYYY')}:
                    {value ? ' Completed' : ' Not Completed'}
                  </Typography>
                </Box>
              )}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const calculatePunishmentDueDate = (punishment) => {
  if (punishment.due_date) return new Date(punishment.due_date);
  
  const createdAt = moment(punishment.created_at);
  let dueDate = createdAt.clone();
  
  switch (punishment.completion_window_unit) {
    case 'hours':
      dueDate.add(punishment.completion_window, 'hours');
      break;
    case 'days':
      dueDate.add(punishment.completion_window, 'days');
      break;
    case 'weeks':
      dueDate.add(punishment.completion_window, 'weeks');
      break;
    default:
      dueDate = null;
  }
  
  return dueDate ? dueDate.toDate() : null;
};

const CalendarView = ({ role }) => {
  const [events, setEvents] = useState([]);
  const [view, setView] = useState('month');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentDate, setCurrentDate] = useState(moment());
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const calendarRef = useRef(null);
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [chastityPeriod, setChastityPeriod] = useState(null);

  const getPeriodStart = (task, date) => {
    const momentDate = moment(date);
    switch (task.recurrence_pattern) {
      case 'daily':
        return momentDate.startOf('day');
      case 'weekly':
      case 'bi-weekly':
        return momentDate.startOf('week');
      case 'monthly':
        return momentDate.startOf('month');
      default:
        return momentDate;
    }
  };

  const getPeriodEnd = (task, date) => {
    const momentDate = moment(date);
    switch (task.recurrence_pattern) {
      case 'daily':
        return momentDate.endOf('day');
      case 'weekly':
      case 'bi-weekly':
        return momentDate.endOf('week');
      case 'monthly':
        return momentDate.endOf('month');
      default:
        return momentDate;
    }
  };

  const getCompletionStatusForPeriod = (task, date) => {
    if (!task.completion_history) return 'pending';

    const periodStart = getPeriodStart(task, date);
    const periodEnd = getPeriodEnd(task, date);

    const completionForPeriod = task.completion_history.find(completion => {
      const completionDate = moment(completion.date);
      return completionDate.isBetween(periodStart, periodEnd, null, '[]');
    });

    return completionForPeriod ? completionForPeriod.status : 'pending';
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
      case 'approved':
        return theme.palette.success.main;
      case 'pending':
      case 'pending_approval':
        return theme.palette.warning.main;
      case 'incomplete':
      case 'failed':
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  };

  const fetchEvents = useCallback(async () => {
    try {
      const [tasksRes, punishmentsRes, rewardsRes, chastityRes] = await Promise.all([
        axiosInstance.get('/tasks'),
        axiosInstance.get('/punishments'),
        axiosInstance.get('/rewards'),
        axiosInstance.get('/chastity')
      ]);

      const taskEvents = tasksRes.data.flatMap(task => {
        if (!task.is_recurring) {
          return [{
            id: `task-${task.id}`,
            title: task.title,
            start: new Date(task.due_date),
            end: new Date(task.due_date),
            allDay: true,
            type: 'task',
            status: task.status,
            details: task,
            resource: {
              icon: <TaskIcon />,
              color: getStatusColor(task.status)
            }
          }];
        }

        const events = [];
        let currentDate = moment().startOf('day');
        const endDate = moment().add(3, 'months').endOf('month');

        while (currentDate.isSameOrBefore(endDate)) {
          if (
            (task.recurrence_pattern === 'daily') ||
            (task.recurrence_pattern === 'weekly' && currentDate.format('dddd').toLowerCase() === task.week_day) ||
            (task.recurrence_pattern === 'bi-weekly' && currentDate.format('dddd').toLowerCase() === task.week_day && currentDate.week() % 2 === 0) ||
            (task.recurrence_pattern === 'monthly' && currentDate.date() === moment(task.due_date).date())
          ) {
            const completionStatus = getCompletionStatusForPeriod(task, currentDate);
            
            events.push({
              id: `task-${task.id}-${currentDate.format('YYYY-MM-DD')}`,
              title: task.title,
              start: currentDate.toDate(),
              end: currentDate.toDate(),
              allDay: true,
              type: 'task',
              status: completionStatus,
              details: { 
                ...task, 
                due_date: currentDate.toDate(),
                period_start: getPeriodStart(task, currentDate),
                period_end: getPeriodEnd(task, currentDate)
              },
              resource: {
                icon: <TaskIcon />,
                color: getStatusColor(completionStatus)
              }
            });
          }
          currentDate.add(1, 'day');
        }
        return events;
      });

      const punishmentEvents = punishmentsRes.data.map(punishment => ({
        id: `punishment-${punishment.id}`,
        title: punishment.title,
        start: calculatePunishmentDueDate(punishment),
        end: calculatePunishmentDueDate(punishment),
        allDay: true,
        type: 'punishment',
        status: punishment.status,
        details: punishment,
        resource: {
          icon: <PunishmentIcon />,
          color: getStatusColor(punishment.status)
        }
      })).filter(event => event.start !== null);

      const rewardEvents = rewardsRes.data.map(reward => ({
        id: `reward-${reward.id}`,
        title: reward.title,
        start: new Date(reward.due_date || reward.created_at),
        end: new Date(reward.due_date || reward.created_at),
        allDay: true,
        type: 'reward',
        status: reward.status,
        details: reward,
        resource: {
          icon: <RewardIcon />,
          color: getStatusColor(reward.status)
        }
      }));

      let chastityEvent = [];
      if (chastityRes.data && chastityRes.data.start_date) {
        const startDate = new Date(chastityRes.data.start_date);
        const endDate = chastityRes.data.is_permanent 
          ? moment().add(3, 'months').toDate() // Show 3 months ahead for permanent
          : new Date(chastityRes.data.end_date);
        
        chastityEvent = [{
          id: 'chastity',
          title: `Chastity Period (${chastityRes.data.days_left} days left)`,
          start: startDate,
          end: endDate,
          allDay: true,
          type: 'chastity',
          details: chastityRes.data,
          resource: {
            icon: <LockIcon />,
            color: theme.palette.warning.main
          }
        }];
      }

      setChastityPeriod(chastityRes.data);
      setEvents([...taskEvents, ...punishmentEvents, ...rewardEvents, ...chastityEvent]);
    } catch (error) {
      console.error('Error fetching events:', error);
      setErrorMessage('Failed to fetch events. Please try again.');
    }
  }, [theme.palette.warning.main]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useLayoutEffect(() => {
    const resizeCalendar = () => {
      if (calendarRef.current) {
        const calendarElement = calendarRef.current;
        const viewportHeight = window.innerHeight;
        const calendarTop = calendarElement.getBoundingClientRect().top;
        const newHeight = viewportHeight - calendarTop - 20;
        calendarElement.style.height = `${newHeight}px`;
      }
    };

    resizeCalendar();
    window.addEventListener('resize', resizeCalendar);

    return () => {
      window.removeEventListener('resize', resizeCalendar);
    };
  }, [view]);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
      // Force a resize after view change
      setTimeout(() => {
        if (calendarRef.current) {
          const resizeEvent = new Event('resize');
          window.dispatchEvent(resizeEvent);
        }
      }, 100);
    }
  };
  
  const handleDateChange = (newDate) => {
    const momentDate = moment(newDate);
    setCurrentDate(momentDate);
    setDatePickerAnchor(null);
  };

  const eventStyleGetter = (event) => {
    let style = {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px',
      opacity: 0.8,
      color: theme.palette.primary.contrastText,
      border: 'none',
      display: 'block'
    };
  
    if (event.type === 'punishment') {
      style.backgroundColor = theme.palette.error.main;
    } else if (event.type === 'reward') {
      style.backgroundColor = theme.palette.success.main;
    } else if (event.type === 'chastity') {
      style = {
        ...style,
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        opacity: 0.6,
        height: '100%',
        width: '100%',
        margin: 0,
        padding: '2px 5px'
      };
    }
  
    return { style };
  };

const handleSelectEvent = (event) => {
  if (event.type === 'chastity') return; // Ignore clicks on chastity events
  setSelectedEvent(event);
  setShowEventDetailsModal(true);
};
  
  const handleInitiateCompletion = () => {
    if (!selectedEvent) return;
  
    const now = moment();
    const eventDate = moment(selectedEvent.start);
    
    // For recurring tasks, check if we're in the correct period
    if (selectedEvent.details.is_recurring) {
      const periodStart = moment(selectedEvent.details.period_start);
      const periodEnd = moment(selectedEvent.details.period_end);
      
      if (!now.isBetween(periodStart, periodEnd, null, '[]')) {
        setErrorMessage("This task cannot be completed yet - it's not within the current period.");
        return;
      }
    } else {
      // For non-recurring tasks, only allow completion if it's not in the future
      if (eventDate.isAfter(now, 'day')) {
        setErrorMessage("Future tasks cannot be completed in advance.");
        return;
      }
    }
    
    setShowEventDetailsModal(false);
    setShowCompletionModal(true);
  };

  const handleSelectSlot = ({ start }) => {
    setCurrentDate(moment(start));
    setView('day');
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setShowCompletionModal(false);
    setShowEditModal(false);
  };

  const handleEditEvent = async (updatedData) => {
    try {
      const eventType = selectedEvent.type;
      const eventId = selectedEvent.details.id;
      
      await axiosInstance.put(`/${eventType}s/${eventId}`, updatedData);
      fetchEvents();
      handleCloseModal();
      setErrorMessage(`${eventType.charAt(0).toUpperCase() + eventType.slice(1)} updated successfully`);
    } catch (error) {
      console.error('Error updating event:', error);
      setErrorMessage(`Failed to update ${selectedEvent.type}. Please try again.`);
    }
  };

  const handleDeleteEvent = async (event) => {
    try {
      await axiosInstance.delete(`/${event.type}s/${event.details.id}`);
      fetchEvents();
      handleCloseModal();
      setErrorMessage(`${event.type.charAt(0).toUpperCase() + event.type.slice(1)} deleted successfully`);
    } catch (error) {
      console.error('Error deleting event:', error);
      setErrorMessage(`Failed to delete ${event.type}. Please try again.`);
    }
  };

  const handleCompleteEvent = async (completionData) => {
    try {
      const eventType = selectedEvent.type;
      const eventId = selectedEvent.details.id;
      
      // Create FormData object
      const formData = new FormData();
      formData.append('type', eventType);
      formData.append('id', eventId);
      
      // Add other form fields from completionData
      if (completionData.note) formData.append('note', completionData.note);
      if (completionData.enjoyment_rating) formData.append('enjoyment_rating', completionData.enjoyment_rating);
      if (completionData.difficulty_rating) formData.append('difficulty_rating', completionData.difficulty_rating);
      if (completionData.humiliation_rating) formData.append('humiliation_rating', completionData.humiliation_rating);
      if (completionData.timer_duration) formData.append('timer_duration', completionData.timer_duration);
      if (completionData.proof) formData.append('proof', completionData.proof);
      
      // If there's location data
      if (completionData.latitude) formData.append('latitude', completionData.latitude);
      if (completionData.longitude) formData.append('longitude', completionData.longitude);
  
      await axiosInstance.post('/completions', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      fetchEvents();
      handleCloseModal();
      setErrorMessage('Completion submitted successfully');
    } catch (error) {
      console.error('Error completing event:', error);
      setErrorMessage('Failed to submit completion. Please try again.');
    }
  };

  const CustomToolbar = ({ label, onNavigate, onView }) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false);
  
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2,
        px: 2
      }}>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          size={isSmallScreen ? "small" : "medium"}
        >
          <ToggleButton value="day" aria-label="day view">
            <Tooltip title="Day View">
              <ViewDay />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="week" aria-label="week view">
            <Tooltip title="Week View">
              <ViewWeek />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="month" aria-label="month view">
            <Tooltip title="Month View">
              <CalendarMonth />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
  
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton onClick={() => onNavigate('PREV')} size={isSmallScreen ? "small" : "medium"}>
            <NavigateBefore />
          </IconButton>
          
          <DatePicker
            open={datePickerOpen}
            onOpen={() => setDatePickerOpen(true)}
            onClose={() => setDatePickerOpen(false)}
            value={currentDate}
            onChange={(newDate) => {
              handleDateChange(newDate);
              setDatePickerOpen(false);
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Button
                ref={inputRef}
                onClick={() => setDatePickerOpen(true)}
                endIcon={<ArrowDropDown />}
                sx={{ minWidth: 120 }}
              >
                {label}
              </Button>
            )}
            views={view === 'month' ? ['year', 'month'] : ['year', 'month', 'day']}
          />
          
          <IconButton onClick={() => onNavigate('NEXT')} size={isSmallScreen ? "small" : "medium"}>
            <NavigateNext />
          </IconButton>
  
          <IconButton 
            onClick={() => onNavigate('TODAY')} 
            size={isSmallScreen ? "small" : "medium"}
            color="primary"
          >
            <Today />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const EventDetailsModal = ({ event, onClose }) => {
    if (!event) return null;
  
    const getStatusChip = (status) => {
      const statusConfig = {
        completed: { color: 'success', icon: <CompletedIcon /> },
        approved: { color: 'success', icon: <CompletedIcon /> },
        pending: { color: 'warning', icon: <PendingIcon /> },
        pending_approval: { color: 'warning', icon: <PendingIcon /> },
        incomplete: { color: 'error', icon: <IncompleteIcon /> },
        failed: { color: 'error', icon: <IncompleteIcon /> }
      };
  
      const config = statusConfig[status] || { color: 'default', icon: null };
  
      return (
        <Chip
          icon={config.icon}
          label={status.replace('_', ' ').toUpperCase()}
          color={config.color}
          size="small"
        />
      );
    };
  
    const canComplete = role === 'submissive' && 
      event.status !== 'completed' && 
      event.status !== 'approved';
  
    return (
      <Dialog 
        open={!!event} 
        onClose={onClose} 
        fullScreen={isSmallScreen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar sx={{ bgcolor: event.resource?.color }}>
              {event.resource?.icon}
            </Avatar>
            <Typography variant="h6" sx={{ 
              textDecoration: event.status === 'completed' || event.status === 'approved' 
                ? 'line-through' 
                : 'none'
            }}>
              {event.title}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card elevation={0} sx={{ height: '100%' }}>
                <CardContent>
                  <Stack spacing={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Category />
                      <Typography variant="subtitle2" color="textSecondary">Type</Typography>
                      <Typography variant="body1">{event.type}</Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Event />
                      <Typography variant="subtitle2" color="textSecondary">Status</Typography>
                      {getStatusChip(event.status)}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AccessTime />
                      <Typography variant="subtitle2" color="textSecondary">Due</Typography>
                      <Typography variant="body1">
                        {moment(event.start).format('MMMM D, YYYY h:mm A')}
                      </Typography>
                    </Box>

                    {event.details?.description && (
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                        <Description sx={{ mt: 0.5 }} />
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">Description</Typography>
                          <Typography variant="body1">{event.details.description}</Typography>
                        </Box>
                      </Box>
                    )}

                    {event.type === 'task' && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Star />
                          <Typography variant="subtitle2" color="textSecondary">Points if completed</Typography>
                          <Typography variant="body1">{event.details.points_complete}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <StarBorder />
                          <Typography variant="subtitle2" color="textSecondary">Points if missed</Typography>
                          <Typography variant="body1">{event.details.points_incomplete}</Typography>
                        </Box>
                      </Box>
                    )}

                    {event.details?.tags && event.details.tags.length > 0 && (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {event.details.tags.map((tag, index) => (
                          <Chip key={index} label={tag} size="small" />
                        ))}
                      </Box>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              {event.details?.completion_history && (
                <CompletionHistory data={event.details.completion_history} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          {canComplete && (
            <Button 
              startIcon={<PlayArrow />}
              onClick={handleInitiateCompletion}
              variant="contained"
              color="primary"
            >
              Complete
            </Button>
          )}
          {role === 'dominant' && (
            <>
              <Button
                startIcon={<Edit />}
                onClick={() => {
                  onClose();
                  setSelectedEvent(event);
                  setShowEditModal(true);
                }}
                variant="outlined"
                color="primary"
              >
                Edit
              </Button>
              <Button
                startIcon={<Delete />}
                onClick={() => handleDeleteEvent(event)}
                variant="outlined"
                color="error"
              >
                Delete
              </Button>
            </>
          )}
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

return (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <Box sx={{ 
      height: {
        xs: 'calc(90vh - 50px)', // Mobile view
        sm: 'calc(100vh - 100px)'   // Desktop view
      },
      display: 'flex', 
      flexDirection: 'column',
      overflow: 'hidden',
      width: '100%',
      margin: '0 auto',
      paddingRight: '0px',
    }}>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={3000}
        onClose={() => setErrorMessage('')}
      >
        <Alert severity="info" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      </Snackbar>
      
      <Box 
        ref={calendarRef}
        sx={{ 
          flexGrow: 1, 
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          width: '100vw',
          margin: '0 auto',
          padding: '0',
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          view={view}
          date={currentDate.toDate()}
          onView={setView}
          onNavigate={date => setCurrentDate(moment(date))}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          selectable={true}
          views={{
            day: true,
            week: true,
            month: true,
          }}
          components={{
            toolbar: CustomToolbar,
            event: ({ event }) => (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 0.5,
                textDecoration: event.status === 'completed' || event.status === 'approved' 
                  ? 'line-through' 
                  : 'none'
              }}>
                {event.resource?.icon}
                <Typography noWrap variant="caption">{event.title}</Typography>
              </Box>
            )
          }}
          className="mobile-calendar"
          showMultiDayTimes={true}
          popup={true}
          selectable={true}
        />
      </Box>
      
      {showEventDetailsModal && (
        <EventDetailsModal 
          event={selectedEvent} 
          onClose={() => {
            setShowEventDetailsModal(false);
            setSelectedEvent(null);
          }}
        />
      )}
      
      <CompletionForm
        show={showCompletionModal}
        handleClose={handleCloseModal}
        handleSubmit={handleCompleteEvent}
        item={selectedEvent?.details}
        itemType={selectedEvent?.type}
        requireProof={selectedEvent?.details?.require_proof}
      />

      {showEditModal && selectedEvent && (
        <Dialog
          open={showEditModal}
          onClose={handleCloseModal}
          fullScreen={isSmallScreen}
        >
          <DialogTitle>Edit {selectedEvent.type}</DialogTitle>
          <DialogContent>
            {selectedEvent.type === 'punishment' && (
              <PunishmentForm
                onSubmit={handleEditEvent}
                punishment={selectedEvent.details}
                onClose={handleCloseModal}
              />
            )}
            {selectedEvent.type === 'reward' && (
              <RewardForm
                onSubmit={handleEditEvent}
                reward={selectedEvent.details}
                onClose={handleCloseModal}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  </LocalizationProvider>
);
};

export default CalendarView;