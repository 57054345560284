import { axiosInstance } from './auth';

export const fetchChastityPeriod = async () => {
  try {
    const response = await axiosInstance.get(`/chastity`);
    console.log('Chastity response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching chastity period:', error);
    throw error;
  }
};

export const updateChastityPeriod = async (days) => {
  try {
    const response = await axiosInstance.put(`/chastity`, { days });
    return response.data;
  } catch (error) {
    console.error('Error updating chastity period:', error);
    throw error;
  }
};

export const startNewChastityPeriod = async (days) => {
  try {
    const response = await axiosInstance.post(`/chastity`, { days });
    return response.data;
  } catch (error) {
    console.error('Error starting new chastity period:', error);
    throw error;
  }
};

export const endChastityPeriod = async () => {
  try {
    const response = await axiosInstance.delete(`/chastity`);
    return response.data;
  } catch (error) {
    console.error('Error ending chastity period:', error);
    throw error;
  }
};

export const getChastityHistory = async () => {
  try {
    const response = await axiosInstance.get(`/chastity/history`);
    return response.data;
  } catch (error) {
    console.error('Error fetching chastity history:', error);
    throw error;
  }
};

export const extendChastityPeriod = async (additionalDays) => {
  try {
    const response = await axiosInstance.post(`/chastity/extend`, { days: additionalDays });
    return response.data;
  } catch (error) {
    console.error('Error extending chastity period:', error);
    throw error;
  }
};

export const reduceChastityPeriod = async (daysToReduce) => {
  try {
    const response = await axiosInstance.post(`/chastity/reduce`, { days: daysToReduce });
    return response.data;
  } catch (error) {
    console.error('Error reducing chastity period:', error);
    throw error;
  }
};

export const getChastityStats = async () => {
  try {
    const response = await axiosInstance.get(`/chastity/stats`);
    return response.data;
  } catch (error) {
    console.error('Error fetching chastity stats:', error);
    throw error;
  }
};

export const incrementChastity = async (days) => {
  try {
    const response = await axiosInstance.post(`/chastity/increment`, { days });
    return response.data;
  } catch (error) {
    console.error('Error incrementing chastity period:', error);
    throw error;
  }
};

export const decrementChastity = async (days) => {
  try {
    const response = await axiosInstance.post(`/chastity/decrement`, { days });
    return response.data;
  } catch (error) {
    console.error('Error decrementing chastity period:', error);
    throw error;
  }
};

const chastityActions = {
  fetchChastityPeriod,
  updateChastityPeriod,
  startNewChastityPeriod,
  endChastityPeriod,
  getChastityHistory,
  extendChastityPeriod,
  reduceChastityPeriod,
  getChastityStats,
  incrementChastity,
  decrementChastity
};

export default chastityActions;
