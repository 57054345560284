import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance, getRole } from './auth';
import jsyaml from 'js-yaml';
import {
  Typography, CircularProgress, Button, List, ListItem, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, Container, Snackbar,
  Card, CardContent, Grid, Chip, Box, TextField, LinearProgress,
  InputAdornment, IconButton, Stepper, Step, StepLabel, Paper,
  Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Assignment as AssignmentIcon,
  PieChart as PieChartIcon,
  QuestionAnswer as QuestionAnswerIcon,
  CompareArrows as CompareArrowsIcon,
  Search as SearchIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  Close as CloseIcon,
  ExpandMore
} from '@mui/icons-material';
import { PieChart, Pie, Cell, Legend, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const Surveys = ({ role }) => {
  const [surveys, setSurveys] = useState([]);
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [responses, setResponses] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showResultsDialog, setShowResultsDialog] = useState(false);
  const [surveyResults, setSurveyResults] = useState(null);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [kinks, setKinks] = useState([]);
  const [completedStages, setCompletedStages] = useState({});
  const [recommendedSurveys, setRecommendedSurveys] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const [surveysResponse, kinksResponse, stagesResponse] = await Promise.all([
        axiosInstance.get('/surveys'),
        axiosInstance.get('/kinks'),
        axiosInstance.get('/completed-survey-stages')
      ]);
      console.log('Fetched surveys:', surveysResponse.data);
      setSurveys(surveysResponse.data || []);
      setKinks(kinksResponse.data || []);
      setCompletedStages(stagesResponse.data || {});
    } catch (error) {
      console.error('Failed to fetch initial data:', error);
      setError('Failed to fetch data. Please try again.');
      setSurveys([]);
      setKinks([]);
      setCompletedStages({});
    } finally {
      setIsLoading(false);
    }
  };

    useEffect(() => {
    fetchInitialData();
  }, [role]);

  const groupSurveys = (surveys) => {
    const assigned = surveys.filter(s => s.assigned && (!s.completed_dom || !s.completed_sub));
    const completed = surveys.filter(s => s.assigned && s.completed_dom && s.completed_sub);
    const unassigned = surveys.filter(s => !s.assigned);
    
    return { assigned, completed, unassigned };
  };

  const handleAssignSurvey = async (surveyId) => {
    try {
      await axiosInstance.post(`/assign-survey`, { surveyId });
      setError('Survey assigned successfully');
      fetchInitialData();
    } catch (error) {
      console.error('Failed to assign survey:', error);
      setError('Failed to assign survey. Please try again.');
    }
  };

  const handleReassignSurvey = async (surveyId) => {
    try {
      await axiosInstance.post(`/reassign-survey`, { surveyId });
      setError('Survey reassigned successfully');
      fetchInitialData();
    } catch (error) {
      console.error('Failed to reassign survey:', error);
      setError('Failed to reassign survey. Please try again.');
    }
  };

  const handleFillSurvey = async (surveyId) => {
    try {
      const response = await axiosInstance.get(`/current-survey?survey_id=${surveyId}`);
      
      let surveyData;
      if (response.headers['content-type'].includes('application/x-yaml')) {
        const yamlText = await response.data;
        surveyData = jsyaml.load(yamlText);
      } else {
        surveyData = response.data;
      }

      const currentUserRole = getRole();

      surveyData.id = surveyId;

      if (surveyData.questions) {
        surveyData.questions = surveyData.questions.map(question => {
          if ('text_dom' in question && 'text_sub' in question) {
            question.text = currentUserRole === 'dominant' ? question.text_dom : question.text_sub;
            delete question.text_dom;
            delete question.text_sub;
          }
          return question;
        });
      }

      setCurrentSurvey(surveyData);
      setCurrentQuestionIndex(0);
      setResponses({});
      setProgress(0);
      setShowSurveyModal(true);
    } catch (error) {
      console.error('Error fetching current survey:', error);
      setError('Failed to fetch survey. Please try again.');
    }
  };

  const handleViewResults = async (surveyId) => {
    try {
      const response = await axiosInstance.get(`/survey-results/${surveyId}`);
      setSurveyResults(response.data);
      setShowResultsDialog(true);
    } catch (error) {
      console.error('Failed to fetch survey results:', error);
      setError('Failed to fetch survey results. Please try again.');
    }
  };

  const renderQuestionResponse = (question) => {
    console.log('Rendering question response:', question); // Add this for debugging
  
    const getMatchColor = (score) => {
      if (score >= 0.8) return 'success.light';
      if (score >= 0.5) return 'warning.light';
      return 'error.light';
    };
  
    const getMatchLabel = (score) => {
      if (score >= 0.8) return 'Perfect Match';
      if (score >= 0.5) return 'Partial Match';
      return 'No Match';
    };
  
    if (question.type === 'select' && question.match_type === 'opposite') {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Dominant:</strong> {question.dominant_response}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Submissive:</strong> {question.submissive_response}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Chip
              icon={question.match_score >= 0.8 ? <CheckCircleIcon /> : <CancelIcon />}
              label={`${getMatchLabel(question.match_score)} (${Math.round(question.match_score * 100)}%)`}
              sx={{ bgcolor: getMatchColor(question.match_score) }}
            />
          </Grid>
        </Grid>
      );
    }
  
    if (Array.isArray(question.dominant_response) || Array.isArray(question.submissive_response)) {
      const domAnswers = Array.isArray(question.dominant_response) ? question.dominant_response : [question.dominant_response];
      const subAnswers = Array.isArray(question.submissive_response) ? question.submissive_response : [question.submissive_response];
      const matchingAnswers = domAnswers.filter(answer => subAnswers.includes(answer));
  
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom><strong>Dominant's Selections:</strong></Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {domAnswers.map((answer, i) => (
                <Chip
                  key={i}
                  label={answer}
                  sx={{
                    bgcolor: matchingAnswers.includes(answer) ? 'success.light' : 'error.light',
                    '& .MuiChip-label': { whiteSpace: 'normal' }
                  }}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom><strong>Submissive's Selections:</strong></Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {subAnswers.map((answer, i) => (
                <Chip
                  key={i}
                  label={answer}
                  sx={{
                    bgcolor: matchingAnswers.includes(answer) ? 'success.light' : 'error.light',
                    '& .MuiChip-label': { whiteSpace: 'normal' }
                  }}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">
              Match Score: {Math.round(question.match_score * 100)}%
            </Typography>
          </Grid>
        </Grid>
      );
    }
  
    // Default single answer display
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            <strong>Dominant:</strong> {question.dominant_response || 'No response'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            <strong>Submissive:</strong> {question.submissive_response || 'No response'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Chip
            label={getMatchLabel(question.match_score)}
            sx={{ bgcolor: getMatchColor(question.match_score) }}
          />
        </Grid>
      </Grid>
    );
  };

  const handleResponseChange = (questionId, value, isCheckbox = false) => {
    setResponses(prev => {
      const newResponses = isCheckbox
        ? {
            ...prev,
            [questionId]: prev[questionId]?.includes(value)
              ? prev[questionId].filter(v => v !== value)
              : [...(prev[questionId] || []), value]
          }
        : { ...prev, [questionId]: value };

      // Calculate new progress
      const totalQuestions = currentSurvey?.questions?.length || 0;
      const answeredQuestions = Object.keys(newResponses).length;
      const newProgress = (answeredQuestions / totalQuestions) * 100;
      setProgress(newProgress);

      return newResponses;
    });
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < (currentSurvey?.questions?.length || 0) - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };

  const handleSubmitSurvey = async () => {
    try {
      await axiosInstance.post(`/submit-survey`, {
        surveyId: currentSurvey.id,
        responses,
        role
      });
      setResponses({});
      setShowSurveyModal(false);
      setCurrentQuestionIndex(0);
      setProgress(0);
      fetchInitialData();
      setError('Survey submitted successfully');
    } catch (error) {
      setError('Failed to submit survey. Please try again.');
    }
  };

  const getKinkAlignment = (survey) => {
    const surveyKink = kinks.find(k => k.name.toLowerCase() === survey.category?.toLowerCase());
    if (!surveyKink) return null;

    if (surveyKink.dominant_rating === 'very much yes' && surveyKink.submissive_rating === 'please') {
      return { color: '#4caf50', emoji: '🥰', tooltip: 'Perfect Match!' };
    } else if (surveyKink.dominant_rating === 'yes' && surveyKink.submissive_rating === 'yes') {
      return { color: '#8bc34a', emoji: '😊', tooltip: 'Both Interested' };
    } else if (surveyKink.dominant_rating === 'maybe' && surveyKink.submissive_rating === 'maybe') {
      return { color: '#ffeb3b', emoji: '🤔', tooltip: 'Both Curious' };
    }
    return { color: 'inherit', emoji: '❓', tooltip: 'Alignment Unknown' };
  };

  const getFilteredSurveys = useCallback(() => {
    return surveys.filter(survey => {
      // For submissive users, only show assigned surveys
      if (role === 'submissive' && !survey.assigned) {
        return false;
      }

      // Filter by search term
      if (searchTerm && !survey.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }

      // Check stage requirements
      if (survey.stage > 1) {
        const previousStage = survey.stage - 1;
        if (!completedStages[previousStage]) {
          return false;
        }
      }

      return true;
    });
  }, [surveys, role, searchTerm, completedStages]);

  const renderSearchBar = () => (
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Search surveys..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{ mb: 2 }}
    />
  );

  const renderSurveyItem = (survey) => {
    const alignment = getKinkAlignment(survey);
    
    return (
      <ListItem 
        key={survey.id}
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'stretch', sm: 'center' },
          gap: 1
        }}
      >
        <ListItemText
          primary={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{ 
                  color: alignment?.color,
                  flexGrow: 1
                }}
              >
                {survey.title}
              </Typography>
              {alignment?.emoji && (
                <Tooltip title={alignment.tooltip}>
                  <Typography sx={{ ml: 1 }}>{alignment.emoji}</Typography>
                </Tooltip>
              )}
              {survey.stage > 1 && (
                <Chip 
                  label={`Stage ${survey.stage}`} 
                  size="small" 
                  sx={{ ml: 1 }}
                />
              )}
            </Box>
          }
          secondary={
            <Box>
              <Typography variant="body2">
                {survey.assigned ? (
                  survey.completed_dom && survey.completed_sub
                    ? "Completed by both parties"
                    : survey.completed_dom
                      ? "Completed by Dominant"
                      : survey.completed_sub
                        ? "Completed by Submissive"
                        : "Not completed"
                ) : "Not assigned"}
              </Typography>
              {survey.recommended && (
                <Chip 
                  label="Recommended Next Survey" 
                  color="primary" 
                  size="small" 
                  sx={{ mt: 0.5 }}
                />
              )}
            </Box>
          }
        />
        <Box sx={{ 
          display: 'flex', 
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          width: { xs: '100%', sm: 'auto' }
        }}>
          {role === 'dominant' && (
            <>
              {!survey.assigned && (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  startIcon={<AssignmentIcon />}
                  onClick={() => handleAssignSurvey(survey.id)}
                >
                  Assign
                </Button>
              )}
            </>
          )}
          {survey.assigned && !survey[`completed_${role}`] && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<QuestionAnswerIcon />}
              onClick={() => handleFillSurvey(survey.id)}
            >
              Fill
            </Button>
          )}
          {survey.assigned && survey.completed_dom && survey.completed_sub && (
            <>
              <Button
                variant="contained"
                color="info"
                startIcon={<PieChartIcon />}
                onClick={() => handleViewResults(survey.id)}
              >
                Results
              </Button>
              {role === 'dominant' && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CompareArrowsIcon />}
                  onClick={() => handleReassignSurvey(survey.id)}
                >
                  Re-assign
                </Button>
              )}
            </>
          )}
        </Box>
      </ListItem>
    );
  };

  const renderSurveyQuestion = (question, index) => {
    const isCurrentQuestion = index === currentQuestionIndex;
    
    return (
      <Box
        key={question.id}
        sx={{
          display: isCurrentQuestion ? 'block' : 'none',
          minHeight: '200px',
          p: 2
        }}
      >
        <Typography variant="h6" gutterBottom>{question.text}</Typography>
        {question.type === 'select' && (
          <Grid container spacing={2}>
            {question.options.map((option) => (
              <Grid item xs={12} sm={6} key={option}>
                <Button
                  fullWidth
                  variant={responses[question.id] === option ? "contained" : "outlined"}
                  onClick={() => handleResponseChange(question.id, option)}
                  sx={{ mb: 1 }}
                >
                  {option}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
        {question.type === 'checkbox' && (
          <Grid container spacing={2}>
            {question.options.map((option) => (
              <Grid item xs={12} sm={6} key={option}>
                <Button
                  fullWidth
                  variant={(responses[question.id] || []).includes(option) ? "contained" : "outlined"}
                  onClick={() => handleResponseChange(question.id, option, true)}
                  sx={{ mb: 1 }}
                >
                  {option}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
        {question.type === 'text' && (
          <TextField
            fullWidth
            multiline={question.long_answer}
            rows={question.long_answer ? 4 : 1}
            value={responses[question.id] || ''}
            onChange={(e) => handleResponseChange(question.id, e.target.value)}
            sx={{ mt: 2 }}
          />
        )}
      </Box>
    );
  };

  const renderSurveyProgress = () => {
    const totalQuestions = currentSurvey?.questions?.length || 0;
    const currentQuestion = currentQuestionIndex + 1;
    
    return (
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2">
            Question {currentQuestion} of {totalQuestions}
          </Typography>
          <Typography variant="body2">
            {Math.round(progress)}% Complete
          </Typography>
        </Box>
        <LinearProgress 
          variant="determinate" 
          value={progress} 
          sx={{ height: 8, borderRadius: 4 }}
        />
      </Box>
    );
  };

  if (isLoading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <CircularProgress />
    </Box>
  );

return (
  <Container maxWidth="md">
    <Typography variant="h4" gutterBottom>Surveys</Typography>
    {renderSearchBar()}
    
    {/* Assigned Surveys Accordion */}
    {groupSurveys(getFilteredSurveys()).assigned.length > 0 && (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Assigned Surveys</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {groupSurveys(getFilteredSurveys()).assigned.map(renderSurveyItem)}
          </List>
        </AccordionDetails>
      </Accordion>
    )}
  
    {/* Completed Surveys Accordion */}
    {groupSurveys(getFilteredSurveys()).completed.length > 0 && (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Completed Surveys</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {groupSurveys(getFilteredSurveys()).completed.map(renderSurveyItem)}
          </List>
        </AccordionDetails>
      </Accordion>
    )}
  
    {/* Unassigned Surveys Accordion - Only shown to dominant users */}
    {role === 'dominant' && groupSurveys(getFilteredSurveys()).unassigned.length > 0 && (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Available Surveys</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {groupSurveys(getFilteredSurveys()).unassigned.map(renderSurveyItem)}
          </List>
        </AccordionDetails>
      </Accordion>
    )}

    {/* Survey Modal */}
    <Dialog 
      open={showSurveyModal} 
      onClose={() => setShowSurveyModal(false)}
      maxWidth="md"
      fullWidth
    >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">{currentSurvey?.title}</Typography>
            <IconButton onClick={() => setShowSurveyModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {currentSurvey?.questions?.map((question, index) => renderSurveyQuestion(question, index))}
          {renderSurveyProgress()}
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex === 0}
            startIcon={<NavigateBeforeIcon />}
          >
            Previous
          </Button>
          <Box sx={{ flex: 1 }} />
          {currentQuestionIndex < (currentSurvey?.questions?.length || 0) - 1 ? (
            <Button
              onClick={handleNextQuestion}
              variant="contained"
              endIcon={<NavigateNextIcon />}
            >
              Next
            </Button>
          ) : (
            <Button
              onClick={handleSubmitSurvey}
              variant="contained"
              color="primary"
              disabled={progress < 100}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showResultsDialog} 
        onClose={() => setShowResultsDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Survey Results</Typography>
            <IconButton onClick={() => setShowResultsDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {surveyResults && (
            <Box>
              <Typography variant="h6" gutterBottom>Overall Alignment</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={[
                      { name: 'Matching', value: surveyResults.matching_count },
                      { name: 'Different', value: surveyResults.total_questions - surveyResults.matching_count }
                    ]}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    <Cell fill="#4caf50" />
                    <Cell fill="#f44336" />
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
              
              <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Detailed Responses</Typography>
              {surveyResults.questions.map((question, index) => (
                <Card key={index} sx={{ mb: 2 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>{question.text}</Typography>
                    {renderQuestionResponse(question)}
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowResultsDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Container>
  );
};

export default Surveys;