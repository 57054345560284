import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, CircularProgress, Snackbar, Card, CardContent,
  Box, Chip, Rating, Grid, Select, MenuItem, FormControl, InputLabel,
  Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PlayArrow, Pause, Stop, ExpandMore } from '@mui/icons-material';
import { getToken } from './auth';

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const Timer = ({ onTimerUpdate }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const intervalRef = React.useRef(null);

  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const pauseTimer = () => {
    if (isRunning) {
      clearInterval(intervalRef.current);
      setIsRunning(false);
    }
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
    onTimerUpdate(time);
    setTime(0);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Timer: {formatTime(time)}</Typography>
      <Button startIcon={<PlayArrow />} onClick={startTimer} disabled={isRunning}>Start</Button>
      <Button startIcon={<Pause />} onClick={pauseTimer} disabled={!isRunning}>Pause</Button>
      <Button startIcon={<Stop />} onClick={stopTimer}>Stop</Button>
    </Box>
  );
};

export const CompletionForm = ({ onSubmit, onClose, item, itemType, requireProof }) => {
  const [proof, setProof] = useState(null);
  const [note, setNote] = useState('');
  const [enjoymentRating, setEnjoymentRating] = useState(0);
  const [difficultyRating, setDifficultyRating] = useState(0);
  const [humiliationRating, setHumiliationRating] = useState(0);
  const [timerDuration, setTimerDuration] = useState(0);
  const [orgasmCountSub, setOrgasmCountSub] = useState(0);
  const [orgasmCountDom, setOrgasmCountDom] = useState(0);
  const [location, setLocation] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', item.id);
    formData.append('type', itemType);
    if (proof) formData.append('proof', proof);
    const finalNote = item.tags && item.tags.includes('exercise')
      ? `${note}\nExercise duration: ${formatTime(timerDuration)}`
      : note;
    formData.append('note', finalNote);
    formData.append('enjoyment_rating', enjoymentRating);
    formData.append('difficulty_rating', difficultyRating);
    formData.append('humiliation_rating', humiliationRating);
    if (item.tags && item.tags.includes('exercise') && timerDuration > 0) {
      formData.append('timer_duration', timerDuration);
    }
    formData.append('orgasm_count_sub', orgasmCountSub);
    formData.append('orgasm_count_dom', orgasmCountDom);
    if (location) {
      formData.append('latitude', location.latitude);
      formData.append('longitude', location.longitude);
    }
    onSubmit(formData);
  };

  const isExerciseTask = item && item.tags && item.tags.includes('exercise');

  useEffect(() => {
    if (item.gps_check_in) {
      navigator.serviceWorker.controller.postMessage({
        type: 'GET_LOCATION'
      });

      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'LOCATION') {
          setLocation(event.data);
        } else if (event.data && event.data.type === 'LOCATION_ERROR') {
          console.error('Error getting location:', event.data.error);
        }
      });
    }
  }, [item.gps_check_in]);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Submit Completion</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {requireProof && (
            <TextField
              type="file"
              accept="image/*,video/*"
              onChange={(e) => setProof(e.target.files[0])}
              fullWidth
              margin="normal"
              required
            />
          )}
          {isExerciseTask && <Timer onTimerUpdate={setTimerDuration} />}
          <TextField
            label="Note"
            multiline
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            fullWidth
            margin="normal"
          />
          {item.gps_check_in && (
            <Box mt={2}>
              <Typography>GPS Location:</Typography>
              {location ? (
                <Typography>
                  Latitude: {location.latitude}, Longitude: {location.longitude}
                </Typography>
              ) : (
                <Typography>Fetching location...</Typography>
              )}
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Typography>Enjoyment:</Typography>
            <Rating
              name="enjoyment"
              value={enjoymentRating}
              onChange={(event, newValue) => setEnjoymentRating(newValue)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Difficulty:</Typography>
            <Rating
              name="difficulty"
              value={difficultyRating}
              onChange={(event, newValue) => setDifficultyRating(newValue)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Humiliation:</Typography>
            <Rating
              name="humiliation"
              value={humiliationRating}
              onChange={(event, newValue) => setHumiliationRating(newValue)}
            />
          </Box>
          <TextField
            type="number"
            label="Submissive Orgasm Count"
            value={orgasmCountSub}
            onChange={(e) => setOrgasmCountSub(Number(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            type="number"
            label="Dominant Orgasm Count"
            value={orgasmCountDom}
            onChange={(e) => setOrgasmCountDom(Number(e.target.value))}
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Submit Completion
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const FeedbackModal = ({ open, onClose, onSubmit, completion }) => {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  const [orgasmCountSub, setOrgasmCountSub] = useState(completion?.orgasm_count_sub || 0);
  const [orgasmCountDom, setOrgasmCountDom] = useState(completion?.orgasm_count_dom || 0);
  const [pointsAwarded, setPointsAwarded] = useState(0);

  useEffect(() => {
    if (completion) {
      setOrgasmCountSub(completion.orgasm_count_sub || 0);
      setOrgasmCountDom(completion.orgasm_count_dom || 0);
      setPointsAwarded(completion.default_points || 0);
    }
  }, [completion]);

  const handleSubmit = (approved) => {
    onSubmit(completion.id, approved, feedback, rating, orgasmCountSub, orgasmCountDom, pointsAwarded);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Provide Feedback</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Feedback"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <Box mt={2}>
          <Typography component="legend">Rating</Typography>
          <Rating
            name="rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </Box>
        <TextField
          margin="dense"
          label="Submissive Orgasm Count"
          type="number"
          fullWidth
          value={orgasmCountSub}
          onChange={(e) => setOrgasmCountSub(Number(e.target.value))}
        />
        <TextField
          margin="dense"
          label="Dominant Orgasm Count"
          type="number"
          fullWidth
          value={orgasmCountDom}
          onChange={(e) => setOrgasmCountDom(Number(e.target.value))}
        />
        <TextField
          margin="dense"
          label="Points Awarded"
          type="number"
          fullWidth
          value={pointsAwarded}
          onChange={(e) => setPointsAwarded(Number(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit(false)} color="secondary">Deny</Button>
        <Button onClick={() => handleSubmit(true)} color="primary">Approve</Button>
      </DialogActions>
    </Dialog>
  );
};

const Completions = ({ role, setPoints }) => {
  const [completions, setCompletions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [selectedCompletion, setSelectedCompletion] = useState(null);
  const [secureFileUrls, setSecureFileUrls] = useState({});

  // New states for sorting, filtering, and grouping
  const [sortBy, setSortBy] = useState('date');
  const [groupBy, setGroupBy] = useState('none');
  const [filterProof, setFilterProof] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fetchCompletions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/completions`);
      setCompletions(response.data);
    } catch (error) {
      console.error('Error fetching completions:', error);
      setErrorMessage('Failed to fetch completions. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCompletions();
  }, [fetchCompletions]);

  const fetchSecureFileUrl = useCallback(async (filename) => {
    try {
      const token = getToken();
      const response = await axiosInstance.get(`/get-file-url/${filename}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Secure URL response:', response.data);
      const secureUrl = `${response.data.url}&jwt=${encodeURIComponent(token)}`;
      console.log('Final secure URL:', secureUrl);
      return secureUrl;
    } catch (error) {
      console.error('Error fetching secure file URL:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    const fetchFileUrls = async () => {
      const urls = {};
      for (const completion of completions) {
        if (completion.proof) {
          urls[completion.proof] = await fetchSecureFileUrl(completion.proof);
          console.log(`Secure URL for ${completion.proof}:`, urls[completion.proof]);
        }
      }
      setSecureFileUrls(urls);
    };
    fetchFileUrls();
  }, [completions, fetchSecureFileUrl]);

  const handleProcessCompletion = async (completionId, approved, feedback, rating, orgasmCountSub, orgasmCountDom, pointsAwarded) => {
    try {
      const response = await axiosInstance.post(`/completions/${completionId}/process`, {
        status: approved ? 'approved' : 'denied',
        dominant_note: feedback,
        rating,
        orgasm_count_sub: orgasmCountSub,
        orgasm_count_dom: orgasmCountDom,
        points_awarded: pointsAwarded
      });
      fetchCompletions();
      setPoints(response.data.new_points);
      setErrorMessage(approved ? 'Completion approved successfully' : 'Completion denied successfully');
    } catch (error) {
      console.error('Error processing completion:', error);
      setErrorMessage('Failed to process completion. Please try again.');
    }
  };

  const handleApprove = async (completionId) => {
    try {
      const completion = completions.find(c => c.id === completionId);
      await handleProcessCompletion(completionId, true, '', 0, completion.orgasm_count_sub, completion.orgasm_count_dom, completion.default_points);
    } catch (error) {
      console.error('Error approving completion:', error);
      setErrorMessage('Failed to approve completion. Please try again.');
    }
  };

  const handleDeny = async (completionId) => {
    try {
      const completion = completions.find(c => c.id === completionId);
      await handleProcessCompletion(completionId, false, '', 0, completion.orgasm_count_sub, completion.orgasm_count_dom, 0);
    } catch (error) {
      console.error('Error denying completion:', error);
      setErrorMessage('Failed to deny completion. Please try again.');
    }
  };

  const getEmojiForRating = (value) => {
    if (value <= 1) return "😢";
    if (value <= 2) return "😕";
    if (value <= 3) return "😐";
if (value <= 4) return "🙂";
   return "😄";
 };

 const sortCompletions = (completionsToSort) => {
   return completionsToSort.sort((a, b) => {
     switch (sortBy) {
       case 'date':
         return new Date(b.completion_date) - new Date(a.completion_date);
       case 'enjoyment':
         return b.enjoyment_rating - a.enjoyment_rating;
       case 'difficulty':
         return b.difficulty_rating - a.difficulty_rating;
       case 'humiliation':
         return b.humiliation_rating - a.humiliation_rating;
       case 'points':
         return b.points_awarded - a.points_awarded;
       case 'status':
         return a.status.localeCompare(b.status);
       default:
         return 0;
     }
   });
 };

 const filterCompletions = (completionsToFilter) => {
   return completionsToFilter.filter((completion) => {
     const hasProof = completion.proof !== null && completion.proof !== undefined;
     const completionDate = new Date(completion.completion_date);
     const isWithinDateRange = (!startDate || completionDate >= startDate) &&
                               (!endDate || completionDate <= endDate);
     return (!filterProof || hasProof) && isWithinDateRange;
   });
 };

 const groupCompletions = (completionsToGroup) => {
   if (groupBy === 'none') {
     return { 'All Completions': completionsToGroup };
   }

   return completionsToGroup.reduce((groups, completion) => {
     const key = groupBy === 'tag' 
       ? (completion.tags && completion.tags.length > 0 ? completion.tags[0] : 'Untagged')
       : completion.item_name || 'Unknown Task';
     
     if (!groups[key]) {
       groups[key] = [];
     }
     groups[key].push(completion);
     return groups;
   }, {});
 };

 const processedCompletions = groupCompletions(filterCompletions(sortCompletions(completions)));

 if (isLoading) return <CircularProgress />;

 return (
   <Container maxWidth="md">
     <Typography variant="h4" gutterBottom>Completions</Typography>
     
     <Box sx={{ mb: 2 }}>
       <FormControl sx={{ mr: 2, minWidth: 120 }}>
         <InputLabel>Sort By</InputLabel>
         <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
           <MenuItem value="date">Date</MenuItem>
           <MenuItem value="enjoyment">Enjoyment</MenuItem>
           <MenuItem value="difficulty">Difficulty</MenuItem>
           <MenuItem value="humiliation">Humiliation</MenuItem>
           <MenuItem value="points">Points</MenuItem>
           <MenuItem value="status">Status</MenuItem>
         </Select>
       </FormControl>
       
       <FormControl sx={{ mr: 2, minWidth: 120 }}>
         <InputLabel>Group By</InputLabel>
         <Select value={groupBy} onChange={(e) => setGroupBy(e.target.value)}>
           <MenuItem value="none">None</MenuItem>
           <MenuItem value="tag">Tag</MenuItem>
           <MenuItem value="task">Task</MenuItem>
         </Select>
       </FormControl>
       
       <FormControlLabel
         control={<Checkbox checked={filterProof} onChange={(e) => setFilterProof(e.target.checked)} />}
         label="Has Proof"
       />
     </Box>
     
     <LocalizationProvider dateAdapter={AdapterDateFns}>
       <Box sx={{ mb: 2 }}>
         <DatePicker
           label="Start Date"
           value={startDate}
           onChange={setStartDate}
           renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} />}
         />
         <DatePicker
           label="End Date"
           value={endDate}
           onChange={setEndDate}
           renderInput={(params) => <TextField {...params} />}
         />
       </Box>
     </LocalizationProvider>

     {Object.entries(processedCompletions).map(([group, groupCompletions]) => (
       <Accordion key={group}>
         <AccordionSummary expandIcon={<ExpandMore />}>
           <Typography>{group} ({groupCompletions.length})</Typography>
         </AccordionSummary>
         <AccordionDetails>
           {groupCompletions.map((completion) => (
             <Card key={completion.id} sx={{ mb: 2 }}>
               <CardContent>
                 <Typography variant="h6">{completion.item_name}</Typography>
                 <Typography variant="body2">Date: {new Date(completion.completion_date).toLocaleString()}</Typography>
                 {completion.note && (
                   <Typography variant="body2">Note: {completion.note}</Typography>
                 )}
                 {completion.proof && secureFileUrls[completion.proof] && (
                   <Box>
                     <Typography variant="body2">Proof:</Typography>
                     {completion.proof_type === 'video' ? (
                       <video width="100%" controls>
                         <source src={secureFileUrls[completion.proof]} type="video/mp4" />
                         Your browser does not support the video tag.
                       </video>
                     ) : completion.proof_type === 'audio' ? (
                       <audio controls>
                         <source src={secureFileUrls[completion.proof]} type="audio/mpeg" />
                         Your browser does not support the audio element.
                       </audio>
                     ) : (
                       <img src={secureFileUrls[completion.proof]} alt="Proof" style={{maxWidth: '100%', height: 'auto'}} />
                     )}
                   </Box>
                 )}
                 {completion.latitude && completion.longitude && (
                   <Typography variant="body2">
                     Location: {completion.latitude}, {completion.longitude}
                   </Typography>
                 )}
                 <Grid container spacing={2} sx={{ mt: 2 }}>
                   <Grid item xs={4}>
                     <Typography variant="body2">
                       Enjoyment: {getEmojiForRating(completion.enjoyment_rating)} {completion.enjoyment_rating}/5
                     </Typography>
                   </Grid>
                   <Grid item xs={4}>
                     <Typography variant="body2">
                       Difficulty: {getEmojiForRating(completion.difficulty_rating)} {completion.difficulty_rating}/5
                     </Typography>
                   </Grid>
                   <Grid item xs={4}>
                     <Typography variant="body2">
                       Humiliation: {getEmojiForRating(completion.humiliation_rating)} {completion.humiliation_rating}/5
                     </Typography>
                   </Grid>
                 </Grid>
                 <Typography variant="body2">Status: {completion.status}</Typography>
                 {completion.status !== 'pending' && completion.status !== 'submitted' && (
                   <>
                     <Typography variant="body2">Points awarded: {completion.points_awarded}</Typography>
                     {completion.dominant_note && (
                       <Typography variant="body2">Dominant's feedback: {completion.dominant_note}</Typography>
                     )}
                   </>
                 )}
                 {completion.tags && (
                   <Box mt={1}>
                     {completion.tags.map((tag, index) => (
                       <Chip key={index} label={tag} size="small" sx={{ mr: 0.5, mt: 0.5 }} />
                     ))}
                   </Box>
                 )}
                 {role === 'dominant' && (completion.status === 'pending' || completion.status === 'submitted') && (
                   <Box mt={2}>
                     <Button
                       variant="contained"
                       color="primary"
                       onClick={() => {
                         setSelectedCompletion(completion);
                         setFeedbackModalOpen(true);
                       }}
                       sx={{ mr: 1 }}
                     >
                       Review
                     </Button>
                     <Button
                       variant="contained"
                       color="primary"
                       onClick={() => handleApprove(completion.id)}
                       sx={{ mr: 1 }}
                     >
                       Approve
                     </Button>
                     <Button
                       variant="contained"
                       color="secondary"
                       onClick={() => handleDeny(completion.id)}
                     >
                       Deny
                     </Button>
                   </Box>
                 )}
               </CardContent>
             </Card>
           ))}
         </AccordionDetails>
       </Accordion>
     ))}
     <FeedbackModal
       open={feedbackModalOpen}
       onClose={() => setFeedbackModalOpen(false)}
       onSubmit={handleProcessCompletion}
       completion={selectedCompletion}
     />
     <Snackbar
       open={!!errorMessage}
       autoHideDuration={6000}
       onClose={() => setErrorMessage('')}
       message={errorMessage}
     />
   </Container>
 );
};

export default Completions;