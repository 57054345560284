import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { 
  Home, 
  Assignment, 
  EmojiEvents, 
  List as ListIcon,
  Chat,
  CalendarToday,
  ViewKanban,
  AutoFixHigh
} from '@mui/icons-material';

// Add in wishlists and other missing features

const AppExplanation = () => {
  return (
    <Box>
      <Typography variant="h6">Welcome to Strictly - Accountable task maangement</Typography>
      <Typography paragraph>
        Strictly is an accountability task management app designed for couples with a kink dynamic to enhance your BDSM experience. It uses a reward and punishment system for building behaviors, self-improvement and kink exploration relying heavily upon users honest completion of surveys to respect limits, explore shared interests and expand relationships.
        Strictly is the most advanced kink friendly task maangement app in existence, supporting chastity, various types of task, reward and punishment completions including location, timer, photo, video, counter, video watching, with a huge number of growing features including:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon><CalendarToday /></ListItemIcon>
          <ListItemText primary="Calendar" secondary="Schedule and track events and tasks" />
        </ListItem>
        <ListItem>
          <ListItemIcon><Home /></ListItemIcon>
          <ListItemText primary="Dashboard" secondary="Overview of your current status and activities" />
        </ListItem>
        <ListItem>
          <ListItemIcon><ViewKanban /></ListItemIcon>
          <ListItemText primary="Kanban Board" secondary="Visual task management and organisation" />
        </ListItem>
        <ListItem>
          <ListItemIcon><Assignment /></ListItemIcon>
          <ListItemText primary="Tasks" secondary="Manage and complete assigned tasks" />
        </ListItem>
        <ListItem>
          <ListItemIcon><EmojiEvents /></ListItemIcon>
          <ListItemText primary="Rewards and Punishments" secondary="Track rewards and punishments" />
        </ListItem>
        <ListItem>
          <ListItemIcon><ListIcon /></ListItemIcon>
          <ListItemText primary="Rules, Kinks, and Toys" secondary="Manage rules, explore kinks, and track toys" />
        </ListItem>
        <ListItem>
          <ListItemIcon><Chat /></ListItemIcon>
          <ListItemText primary="Chat" secondary="Secure messaging between partners" />
        </ListItem>
        <ListItem>
          <ListItemIcon><AutoFixHigh /></ListItemIcon>
          <ListItemText primary="Automation" secondary="Create custom workflows and automations, for laziness or extra fun" />
        </ListItem>
      </List>
    </Box>
  );
};

export default AppExplanation;