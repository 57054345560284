import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { axiosInstance } from './auth';

const TaskSelectionDialog = ({ open, onClose, onAdd, columns, initialColumn }) => {
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [sortBy, setSortBy] = useState('dueDate');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchAvailableTasks();
      setSelectedTasks([]); // Reset selections when dialog opens
      setSelectedColumn(initialColumn || ''); // Use initialColumn if provided
    }
  }, [open, initialColumn]);

  const fetchAvailableTasks = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/tasks');
      // Filter out tasks that are already in kanban
      const availableTasks = response.data.filter(task => !task.kanban_status);
      setTasks(availableTasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
    setLoading(false);
  };

  const handleSort = (tasks) => {
    switch (sortBy) {
      case 'dueDate':
        return [...tasks].sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
      case 'alphabetical':
        return [...tasks].sort((a, b) => a.title.localeCompare(b.title));
      case 'category':
        return [...tasks].sort((a, b) => {
          const catA = a.tags[0] || '';
          const catB = b.tags[0] || '';
          return catA.localeCompare(catB);
        });
      default:
        return tasks;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ mb: 2 }}>Add Tasks to Kanban</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Sort By</InputLabel>
            <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
              <MenuItem value="dueDate">Due Date</MenuItem>
              <MenuItem value="alphabetical">Alphabetical</MenuItem>
              <MenuItem value="category">Category</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Select Column</InputLabel>
            <Select
              value={selectedColumn}
              onChange={(e) => setSelectedColumn(e.target.value)}
              required
            >
              {columns.map(column => (
                <MenuItem key={column.id} value={column.id}>
                  {column.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <List>
            {handleSort(tasks).map((task) => (
              <ListItem
                key={task.id}
                button
                onClick={() => {
                  const newSelected = selectedTasks.includes(task.id)
                    ? selectedTasks.filter(id => id !== task.id)
                    : [...selectedTasks, task.id];
                  setSelectedTasks(newSelected);
                }}
              >
                <Checkbox
                  checked={selectedTasks.includes(task.id)}
                  onChange={() => {}}
                />
                <ListItemText
                  primary={task.title}
                  secondary={`Due: ${new Date(task.due_date).toLocaleDateString()}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            if (selectedColumn) {
              onAdd(tasks.filter(task => selectedTasks.includes(task.id)), selectedColumn);
              onClose();
            }
          }}
          color="primary"
          disabled={!selectedColumn || selectedTasks.length === 0}
        >
          Add Selected
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteColumnDialog = ({ open, onClose, column, columns, onDelete }) => {
  const [moveTasksTo, setMoveTasksTo] = useState('');
  const hasOtherColumns = columns.filter(c => c.id !== column?.id).length > 0;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Column</DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          Are you sure you want to delete the column "{column?.title}"?
        </Typography>
        {hasOtherColumns && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Move tasks to (optional)</InputLabel>
            <Select
              value={moveTasksTo}
              onChange={(e) => setMoveTasksTo(e.target.value)}
            >
              <MenuItem value="">Remove from kanban</MenuItem>
              {columns
                .filter(c => c.id !== column?.id)
                .map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={() => onDelete(moveTasksTo)} 
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NewColumnDialog = ({ open, onClose, onAdd, title, setTitle }) => (
  <Dialog open={open} onClose={onClose} maxWidth="xs">
    <DialogTitle>New Column</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Column Title"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        sx={{ mt: 1 }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onAdd} color="primary">
        Add
      </Button>
    </DialogActions>
  </Dialog>
);

const AddColumnButton = ({ position, onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      width: '40px',
      height: '40px',
      alignSelf: 'center',
      mx: 1,
      backgroundColor: 'rgba(0,0,0,0.04)',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.08)',
      },
    }}
  >
    <AddIcon fontSize="small" />
  </IconButton>
);

const KanbanView = () => {
  const [columns, setColumns] = useState([]);
  const [tasks, setTasks] = useState({});
  const [showTaskSelector, setShowTaskSelector] = useState(false);
  const [showNewColumnDialog, setShowNewColumnDialog] = useState(false);
  const [newColumnTitle, setNewColumnTitle] = useState('');
  const [selectedColumnForAdd, setSelectedColumnForAdd] = useState(null);
  const [insertPosition, setInsertPosition] = useState(null);
  const [deleteColumnDialog, setDeleteColumnDialog] = useState({ open: false, column: null });
  const [editingColumn, setEditingColumn] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const [taskDetailsModal, setTaskDetailsModal] = useState({ open: false, task: null });

  useEffect(() => {
    fetchKanbanData();
  }, []);

  const fetchKanbanData = async () => {
      try {
          const response = await axiosInstance.get('/kanban-tasks');
          const { columns: fetchedColumns, tasks: fetchedTasks } = response.data;
          
          setColumns(fetchedColumns);
          
          // Group tasks by column
          const tasksByColumn = {};
          fetchedColumns.forEach(column => {
              const columnTag = column.title.toLowerCase().replace(' ', '_');
              tasksByColumn[column.id] = fetchedTasks.filter(
                  task => task.kanban_status === columnTag
              );
              console.log(`Column ${column.title} tasks:`, tasksByColumn[column.id]); // Debug log
          });
          
          setTasks(tasksByColumn);
          console.log('Tasks grouped by column:', tasksByColumn);
      } catch (error) {
          console.error('Error fetching kanban data:', error);
      }
  };

  const handleAddColumn = async () => {
    try {
      const response = await axiosInstance.post('/kanban-columns', {
        title: newColumnTitle,
        position: insertPosition
      });
      await fetchKanbanData();
      setNewColumnTitle('');
      setShowNewColumnDialog(false);
      setInsertPosition(null);
    } catch (error) {
      console.error('Error adding column:', error);
    }
  };

  const handleDeleteColumn = async (columnId, moveTasksTo) => {
    try {
      if (moveTasksTo) {
        const targetColumn = columns.find(col => col.id === moveTasksTo);
        const tasksToMove = tasks[columnId] || [];
        
        await Promise.all(tasksToMove.map(task =>
          axiosInstance.put(`/tasks/${task.id}/kanban-status`, {
            status: targetColumn.title.toLowerCase().replace(' ', '_')
          })
        ));
      } else {
        const tasksToUpdate = tasks[columnId] || [];
        await Promise.all(tasksToUpdate.map(task =>
          axiosInstance.put(`/tasks/${task.id}/kanban-status`, {
            status: null
          })
        ));
      }

      await axiosInstance.delete(`/kanban-columns/${columnId}`);
      await fetchKanbanData();
      setDeleteColumnDialog({ open: false, column: null });
    } catch (error) {
      console.error('Error deleting column:', error);
    }
  };

  const handleAddTasks = async (selectedTasks, columnId) => {
      try {
          const column = columns.find(col => col.id === columnId);
          if (!column) {
              console.error('Column not found:', columnId);
              return;
          }
  
          const columnTag = column.title.toLowerCase().replace(' ', '_');
          console.log('Adding tasks to column:', columnTag);
          console.log('Selected tasks:', selectedTasks);
  
          for (const task of selectedTasks) {
              try {
                  console.log(`Updating task ${task.id} with status ${columnTag}`);
                  const response = await axiosInstance.put(`/tasks/${task.id}/kanban-status`, {
                      status: columnTag
                  });
                  console.log(`Task ${task.id} update response:`, response.data);
              } catch (error) {
                  console.error(`Error updating task ${task.id}:`, error);
                  console.error('Error response:', error.response?.data);
                  throw error;
              }
          }
  
          await fetchKanbanData();
      } catch (error) {
          console.error('Error adding tasks to column:', error);
          if (error.response) {
              console.error('Error response:', error.response.data);
              console.error('Status code:', error.response.status);
          }
      }
  };

  const TaskDetailsModal = ({ open, task, onClose }) => {
    if (!task) return null;
    
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{task.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Description: {task.description}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Due Date: {new Date(task.due_date).toLocaleDateString()}
          </Typography>
          {/* Add more task details as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const onDragEnd = async (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;

    const sourceColumn = columns.find(col => col.id === parseInt(source.droppableId));
    const destColumn = columns.find(col => col.id === parseInt(destination.droppableId));

    if (!sourceColumn || !destColumn) return;

    if (source.droppableId === destination.droppableId) {
      // Reorder within same column
      const column = [...tasks[source.droppableId]];
      const [removed] = column.splice(source.index, 1);
      column.splice(destination.index, 0, removed);
      setTasks({
        ...tasks,
        [source.droppableId]: column
      });
    } else {
      // Move between columns
      const sourceCol = [...tasks[source.droppableId]];
      const destCol = [...tasks[destination.droppableId]];
      const [moved] = sourceCol.splice(source.index, 1);
      destCol.splice(destination.index, 0, moved);
      
      setTasks({
        ...tasks,
        [source.droppableId]: sourceCol,
        [destination.droppableId]: destCol
      });

      // Update backend
      try {
        await axiosInstance.put(`/tasks/${draggableId}/kanban-status`, {
          status: destColumn.title.toLowerCase().replace(' ', '_')
        });
      } catch (error) {
        console.error('Error updating task status:', error);
        // Revert the UI change if the backend update fails
        await fetchKanbanData();
      }
    }
  };

  return (
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Box 
            sx={{ 
              display: 'flex',
              overflowX: 'auto',
              p: 1,
              flexGrow: 1,
              position: 'relative', // Add this
              '&::-webkit-scrollbar': {
                height: '8px',
                display: 'none', // Hide by default
              },
              '&:hover::-webkit-scrollbar': {
                display: 'block', // Show on hover
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(0,0,0,0.05)',
                borderRadius: '4px',
                position: 'absolute',
                bottom: 0,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.3)',
                },
              },
              // Firefox scrollbar styling
              scrollbarWidth: 'none', // Hide by default
              '&:hover': {
                scrollbarWidth: 'thin', // Show on hover
              },
              scrollbarColor: 'rgba(0,0,0,0.2) rgba(0,0,0,0.05)',
            }}
          >
          <AddColumnButton
            position={0}
            onClick={() => {
              setInsertPosition(0);
              setShowNewColumnDialog(true);
            }}
          />

        {columns.sort((a, b) => a.position - b.position).map((column, index) => (
          <React.Fragment key={column.id}>
            <Paper
              sx={{ 
                minWidth: 280, // Slightly smaller
                width: 280,
                m: 0.5, // Reduced margin
                display: 'flex',
                flexDirection: 'column',
                flexShrink: 0,
              }}
            >
              <Box sx={{ 
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                {editingColumn === column.id ? (
                  <TextField
                    autoFocus
                    value={editingTitle}
                    size="small"
                    onBlur={async () => {
                      if (editingTitle !== column.title) {
                        try {
                          // First update the column title
                          await axiosInstance.put(`/kanban-columns/${column.id}`, {
                            title: editingTitle
                          });
                
                          // Then update all tasks in this column with the new status
                          const columnTasks = tasks[column.id] || [];
                          await Promise.all(columnTasks.map(task =>
                            axiosInstance.put(`/tasks/${task.id}/kanban-status`, {
                              status: editingTitle.toLowerCase().replace(' ', '_')
                            })
                          ));
                
                          await fetchKanbanData(); // Refresh the data
                        } catch (error) {
                          console.error('Error updating column:', error);
                        }
                      }
                      setEditingColumn(null);
                    }}
                    onChange={(e) => setEditingTitle(e.target.value)}
                    onKeyPress={async (e) => {
                      if (e.key === 'Enter') {
                        if (editingTitle !== column.title) {
                          try {
                            // First update the column title
                            await axiosInstance.put(`/kanban-columns/${column.id}`, {
                              title: editingTitle
                            });
                
                            // Then update all tasks in this column with the new status
                            const columnTasks = tasks[column.id] || [];
                            await Promise.all(columnTasks.map(task =>
                              axiosInstance.put(`/tasks/${task.id}/kanban-status`, {
                                status: editingTitle.toLowerCase().replace(' ', '_')
                              })
                            ));
                
                            await fetchKanbanData(); // Refresh the data
                          } catch (error) {
                            console.error('Error updating column:', error);
                          }
                        }
                        setEditingColumn(null);
                      }
                    }}
                  />
                ) : (
                  <Typography 
                    variant="h6" 
                    onClick={() => {
                      setEditingColumn(column.id);
                      setEditingTitle(column.title);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    {column.title}
                  </Typography>
                )}
                <IconButton
                  size="small"
                  onClick={() => setDeleteColumnDialog({ open: true, column })}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Droppable droppableId={String(column.id)}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{ 
                      flexGrow: 1,
                      p: 1,
                      overflowY: 'auto',
                      minHeight: '200px'
                    }}
                  >
                    {tasks[column.id]?.map((task, index) => (
                      <Draggable
                        key={task.id}
                        draggableId={String(task.id)}
                        index={index}
                      >
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{ p: 2, mb: 1, cursor: 'pointer' }}
                            onClick={() => setTaskDetailsModal({ open: true, task })}
                          >
                            <Typography>{task.title}</Typography>
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
              <Box sx={{ 
                p: 1, 
                display: 'flex', 
                justifyContent: 'center',
                borderTop: 1,
                borderColor: 'divider'
              }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    setSelectedColumnForAdd(column.id);
                    setShowTaskSelector(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Paper>
            
            <AddColumnButton
              position={column.position + 1}
              onClick={() => {
                setInsertPosition(column.position + 1);
                setShowNewColumnDialog(true);
              }}
            />
          </React.Fragment>
        ))}
        </Box>
      </DragDropContext>

      <TaskSelectionDialog
        open={showTaskSelector}
        onClose={() => {
          setShowTaskSelector(false);
          setSelectedColumnForAdd(null);
        }}
        onAdd={handleAddTasks}
        columns={columns}
        initialColumn={selectedColumnForAdd}
      />

      <NewColumnDialog
        open={showNewColumnDialog}
        onClose={() => {
          setShowNewColumnDialog(false);
          setNewColumnTitle('');
          setInsertPosition(null);
        }}
        onAdd={handleAddColumn}
        title={newColumnTitle}
        setTitle={setNewColumnTitle}
      />

      {taskDetailsModal.open && (
        <TaskDetailsModal
          open={taskDetailsModal.open}
          task={taskDetailsModal.task}
          onClose={() => setTaskDetailsModal({ open: false, task: null })}
        />
      )}

    <DeleteColumnDialog
      open={deleteColumnDialog.open}
      onClose={() => setDeleteColumnDialog({ open: false, column: null })}
      column={deleteColumnDialog.column}
      columns={columns}
      onDelete={(moveTasksTo) => handleDeleteColumn(deleteColumnDialog.column?.id, moveTasksTo)}
    />
    </Box>
  );
};

export default KanbanView;