import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Dialog, DialogTitle, DialogActions, DialogContent,
  TextField, CircularProgress, Snackbar, Fab, Card, CardContent, DialogContentText,
  Box, Chip, Rating, Accordion, AccordionSummary, AccordionDetails,
  List, ListItem, ListItemText, Switch, FormControlLabel, IconButton,
  Button, Tooltip, InputAdornment
} from '@mui/material';
import { Add, ExpandMore, Casino, Edit, Delete, Assignment, Camera } from '@mui/icons-material';
import { examples } from './examples';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import WheelOfPrizes from './WheelOfPrizes';

const POINT_PRESETS = [50, 100, 250, 500, 1000];

const generateRandomPoints = () => {
  const pointValues = [1, 100, 250, 500, 1000];
  return pointValues[Math.floor(Math.random() * pointValues.length)];
};

const replaceNames = (text, submissiveName, dominantName) => {
  console.log('replaceNames input:', {
    text,
    submissiveName,
    dominantName
  });
  
  if (typeof text !== 'string') {
    console.warn('Invalid text input:', text);
    return '';
  }
  
  const capitalizedSubmissive = submissiveName 
    ? submissiveName.charAt(0).toUpperCase() + submissiveName.slice(1) 
    : 'Submissive';
  
  const capitalizedDominant = dominantName
    ? dominantName.charAt(0).toUpperCase() + dominantName.slice(1)
    : 'Dominant';

  console.log('Capitalized names:', {
    capitalizedSubmissive,
    capitalizedDominant
  });

  const result = text
    .replace(/\b(submissive|Sub|sub|sub's)\b/g, (match) => {
      console.log('Replacing submissive match:', match);
      if (match === "sub's") return `${capitalizedSubmissive}'s`;
      return capitalizedSubmissive;
    })
    .replace(/\b(dom|dominant|Dom|Dom's|dom's)\b/g, (match) => {
      console.log('Replacing dominant match:', match);
      if (match === "Dom's" || match === "dom's") return `${capitalizedDominant}'s`;
      return capitalizedDominant;
    });

  console.log('replaceNames output:', result);
  return result;
};

const randomizeNumbers = (text) => {
  if (typeof text !== 'string') return '';
  
  return text.replace(/(\d+x\d*|\d*x\d+|(?<=\s)x(?=\s))/g, (match) => {
    let min, max;
    
    if (match === 'x') {
      min = 1;
      max = 40;
    } else if (match.startsWith('x')) {
      // x30 pattern
      max = parseInt(match.substring(1));
      min = 1;
    } else if (match.endsWith('x')) {
      // 20x pattern
      min = parseInt(match.substring(0, match.length - 1));
      max = min > 40 ? 2 * min : 40;
    } else {
      // 5x12 pattern
      const [before, after] = match.split('x').map(num => parseInt(num));
      min = before;
      max = after;
    }
    
    if (min > max) [min, max] = [max, min];
    
    let result = Math.floor(Math.random() * (max - min + 1)) + min;
    
    if (match === 'x' && result >= 10) {
      result = Math.round(result / 5) * 5;
    }
    
    return ` ${result} `;
  });
};

const RewardForm = ({ onSubmit, reward, onClose, kinks = [], userNames }) => {
  const [title, setTitle] = useState(reward ? reward.title : '');
  const [description, setDescription] = useState(reward ? reward.description : '');
  const [category, setCategory] = useState(reward ? reward.category : '');
  const [pointsRequired, setPointsRequired] = useState(reward ? reward.points_required : POINT_PRESETS[0]);
  const [dueDate, setDueDate] = useState(reward && reward.due_date ? new Date(reward.due_date) : null);
  const [requireProof, setRequireProof] = useState(reward ? reward.require_proof : false);
  const [customPoints, setCustomPoints] = useState(false);
  const [examplesExpanded, setExamplesExpanded] = useState(true);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      id: reward ? reward.id : null,
      title,
      description,
      category,
      points_required: pointsRequired,
      due_date: dueDate ? dueDate.toISOString().slice(0, 19).replace('T', ' ') : null,
      require_proof: requireProof
    });
    onClose();
  };

  const getFilteredExamples = useCallback(() => {
    let filteredExamples = examples.rewards.filter(example =>
      kinks.some(kink => kink.name.toLowerCase() === example.category.toLowerCase() && 
                 kink.submissive_rating !== 'hard no' && 
                 kink.dominant_rating !== 'hard no')
    );
    return filteredExamples.sort((a, b) => {
      const aKink = kinks.find(k => k.name.toLowerCase() === a.category.toLowerCase());
      const bKink = kinks.find(k => k.name.toLowerCase() === b.category.toLowerCase());
      const aRelevance = (aKink && aKink.dominant_rating === 'very much yes' && aKink.submissive_rating === 'please') ? 2 :
                         (aKink && (aKink.dominant_rating === 'yes' || aKink.submissive_rating === 'yes')) ? 1 : 0;
      const bRelevance = (bKink && bKink.dominant_rating === 'very much yes' && bKink.submissive_rating === 'please') ? 2 :
                         (bKink && (bKink.dominant_rating === 'yes' || bKink.submissive_rating === 'yes')) ? 1 : 0;
      return bRelevance - aRelevance;
    });
  }, [kinks]);

  const filteredExamples = getFilteredExamples();

  const handleExampleClick = (example) => {
    const parts = example.text.split(' - ', 2);
    if (parts.length === 2) {
      setTitle(replaceNames(
        parts[0].trim(),
        userNames.submissive?.username || '',
        userNames.dominant?.username || ''
      ));
      setDescription(
        randomizeNumbers(
          replaceNames(
            parts[1].trim(),
            userNames.submissive?.username || '',
            userNames.dominant?.username || ''
          )
        )
      );
    } else {
      setTitle(replaceNames(
        example.text.trim(),
        userNames.submissive?.username || '',
        userNames.dominant?.username || ''
      ));
      setDescription('');
    }
    setCategory(example.category);
    setExamplesExpanded(false);
  };

  const getKinkAlignment = (kink) => {
    if (kink.dominant_rating === 'very much yes' && kink.submissive_rating === 'please') return '#4caf50';
    if (kink.dominant_rating === 'yes' && kink.submissive_rating === 'yes') return '#8bc34a';
    if (kink.dominant_rating === 'maybe' && kink.submissive_rating === 'maybe') return '#ffeb3b';
    return 'inherit';
  };

  const getKinkEmoji = (rating) => {
    switch (rating) {
      case 'hard no': return '🚫';
      case 'if forced': return '😬';
      case 'maybe': return '🤔';
      case 'yes': return '😊';
      case 'please': return '😍';
      case 'very much yes': return '🥰';
      default: return '❓';
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Accordion 
        expanded={examplesExpanded}
        onChange={(_, isExpanded) => setExamplesExpanded(isExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Example Rewards</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            {kinks.filter(kink => 
              kink.dominant_rating !== 'hard no' && 
              kink.submissive_rating !== 'hard no' &&
              filteredExamples.some(example => example.category.toLowerCase() === kink.name.toLowerCase())
            ).map((kink) => (
              <Accordion key={kink.name}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography sx={{ 
                      flexGrow: 1, 
                      color: getKinkAlignment(kink) === 'inherit' ? 'text.primary' : getKinkAlignment(kink) 
                    }}>
                      {kink.name}
                    </Typography>
                    <Tooltip title={`${userNames.dominant}: ${kink.dominant_rating}, ${userNames.submissive}: ${kink.submissive_rating}`}>
                      <Typography>
                        {getKinkEmoji(kink.dominant_rating)} {getKinkEmoji(kink.submissive_rating)}
                      </Typography>
                    </Tooltip>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {filteredExamples
                      .filter(example => example.category.toLowerCase() === kink.name.toLowerCase())
                      .map((example, index) => {
                        
                        const parts = example.text.split(' - ', 2);
                        const title = parts[0] || '';
                        const description = parts[1] || '';
                        // First replace names, then randomize numbers
                        const processedTitle = replaceNames(
                          title.trim(),
                          userNames.submissive?.username || '',
                          userNames.dominant?.username || ''
                        );
                        const processedDescription = description 
                          ? randomizeNumbers(
                              replaceNames(
                                description.trim(),
                                userNames.submissive?.username || '',
                                userNames.dominant?.username || ''
                              )
                            )
                          : '';
                        const displayText = description 
                          ? `${processedTitle} - ${processedDescription}`
                          : processedTitle;
                        return (
                          <ListItem 
                            key={index} 
                            button 
                            onClick={() => handleExampleClick({
                              ...example,
                              text: displayText
                            })}
                          >
                            <ListItemText primary={displayText} />
                          </ListItem>
                        );
                      })}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>

      <TextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        label="Category"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        margin="normal"
      />
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography>Points Required:</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
          {POINT_PRESETS.map((point) => (
            <Button
              key={point}
              variant={!customPoints && pointsRequired === point ? "contained" : "outlined"}
              onClick={() => {
                setCustomPoints(false);
                setPointsRequired(point);
              }}
              size="small"
            >
              {point}
            </Button>
          ))}
          <Button
            variant={customPoints ? "contained" : "outlined"}
            onClick={() => setCustomPoints(true)}
            size="small"
          >
            Custom
          </Button>
        </Box>
        {customPoints && (
          <TextField
            type="number"
            value={pointsRequired}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (!isNaN(value)) {
                setPointsRequired(Math.max(0, Math.min(10000, value)));
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">points</InputAdornment>,
            }}
            size="small"
            sx={{ mt: 1 }}
            fullWidth
          />
        )}
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label="Due Date"
          value={dueDate}
          onChange={setDueDate}
          renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
        />
      </LocalizationProvider>
      <FormControlLabel
        control={
          <Switch
            checked={requireProof}
            onChange={(e) => setRequireProof(e.target.checked)}
          />
        }
        label="Require Proof"
      />
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        {reward ? 'Update Reward' : 'Create Reward'}
      </Button>
    </form>
  );
};

const CompletionForm = ({ onSubmit, onClose }) => {
  const [note, setNote] = useState('');
  const [enjoymentRating, setEnjoymentRating] = useState(0);
  const [difficultyRating, setDifficultyRating] = useState(0);
  const [humiliationRating, setHumiliationRating] = useState(0);
  const [orgasmCountSub, setOrgasmCountSub] = useState(0);
  const [orgasmCountDom, setOrgasmCountDom] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      note,
      enjoyment_rating: enjoymentRating,
      difficulty_rating: difficultyRating,
      humiliation_rating: humiliationRating,
      orgasm_count_sub: orgasmCountSub,
      orgasm_count_dom: orgasmCountDom
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="Note"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        margin="normal"
        multiline
        rows={4}
      />
      <Box sx={{ mt: 2 }}>
        <Typography>Enjoyment:</Typography>
        <Rating
          name="enjoyment"
          value={enjoymentRating}
          onChange={(event, newValue) => {
            setEnjoymentRating(newValue);
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Difficulty:</Typography>
        <Rating
          name="difficulty"
          value={difficultyRating}
          onChange={(event, newValue) => {
            setDifficultyRating(newValue);
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography>Humiliation:</Typography>
        <Rating
          name="humiliation"
          value={humiliationRating}
          onChange={(event, newValue) => {
            setHumiliationRating(newValue);
          }}
        />
      </Box>
      <TextField
        fullWidth
        label="Submissive Orgasm Count"
        type="number"
        value={orgasmCountSub}
        onChange={(e) => setOrgasmCountSub(Number(e.target.value))}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Dominant Orgasm Count"
        type="number"
        value={orgasmCountDom}
        onChange={(e) => setOrgasmCountDom(Number(e.target.value))}
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        Submit Completion
      </Button>
    </form>
  );
};

const Rewards = ({ role, points, setPoints, kinks, sortBy, groupBy }) => {
  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [userNames, setUserNames] = useState({ dominant: '', submissive: '' });
  const [wheelPunishments, setWheelPunishments] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rewardToDelete, setRewardToDelete] = useState(null);

  const fetchRewards = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/rewards`);
      setRewards(response.data || []);
    } catch (error) {
      console.error('Error fetching rewards:', error);
      if (error.response && error.response.status === 404) {
        setErrorMessage('Rewards endpoint not found. Please check server configuration.');
      } else {
        setErrorMessage('Failed to fetch rewards. Please try again.');
      }
      setRewards([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRewards();
    fetchUserNames();
  }, [fetchRewards]);
  
  const fetchUserNames = async () => {
    try {
      const response = await axiosInstance.get(`/user-names`);
      setUserNames({
        submissive: { 
          name: response.data.submissive.username, 
          id: response.data.submissive.id 
        },
        dominant: { 
          name: response.data.dominant.username, 
          id: response.data.dominant.id 
        }
      });
    } catch (error) {
      console.error('Error fetching user names:', error);
      setErrorMessage('Failed to fetch user names. Please try again.');
    }
  };

  const handleCreateReward = async (rewardData) => {
    try {
      await axiosInstance.post(`/rewards`, rewardData);
      fetchRewards();
      setShowRewardModal(false);
    } catch (error) {
      console.error('Error creating reward:', error);
      setErrorMessage('Failed to create reward. Please try again.');
    }
  };

  const handleEditReward = async (rewardData) => {
    try {
      await axiosInstance.put(`/rewards/${rewardData.id}`, rewardData);
      fetchRewards();
      setShowRewardModal(false);
    } catch (error) {
      console.error('Error editing reward:', error);
      setErrorMessage('Failed to edit reward. Please try again.');
    }
  };

  const handleDeleteReward = async (rewardId) => {
    setRewardToDelete(rewardId);
    setDeleteDialogOpen(true);
  };
  
  const confirmDeleteReward = async () => {
    try {
      await axiosInstance.delete(`/rewards/${rewardToDelete}`);
      fetchRewards();
      setDeleteDialogOpen(false);
      setRewardToDelete(null);
    } catch (error) {
      console.error('Error deleting reward:', error);
      setErrorMessage('Failed to delete reward. Please try again.');
    }
  };

  const handleAssignReward = async (rewardId) => {
    try {
      const response = await axiosInstance.post(`/rewards/${rewardId}/assign`);
      setPoints(response.data.new_points);
      fetchRewards();
    } catch (error) {
      console.error('Error assigning reward:', error);
      if (error.response && error.response.status === 400) {
        setErrorMessage("Not enough points to assign reward");
      } else {
        setErrorMessage('Failed to assign reward. Please try again.');
      }
    }
  };

  const handleRemoveAllRewards = async () => {
    try {
      const response = await axiosInstance.post('/remove-all-rewards');
      if (response.status === 200) {
        setPoints(response.data.new_points);
        await fetchRewards();
        setErrorMessage('All rewards have been removed and points returned to the submissive.');
      }
    } catch (error) {
      console.error('Error removing all rewards:', error);
      setErrorMessage('Failed to remove all rewards. Please try again.');
    }
    setOpenConfirmDialog(false);
  };

const handleRandomReward = async () => {
  try {
    // Fetch user names if not available
    if (!userNames.submissive.name || !userNames.dominant.name) {
      const response = await axiosInstance.get('/user-names');
      setUserNames(response.data);
    }

    const filteredExamples = getFilteredExamples();

    if (filteredExamples.length === 0) {
      setErrorMessage('No eligible rewards found. Please check your kink settings.');
      return;
    }

    let rewardCreated = false;
    let attempts = 0;
    const maxAttempts = 10;

    while (!rewardCreated && attempts < maxAttempts) {
      attempts++;
      const randomReward = filteredExamples[Math.floor(Math.random() * filteredExamples.length)];
      
      if (!randomReward || !randomReward.text) {
        continue;
      }

      let randomRewardText = randomReward.text;

      const randomizeNumbers = (text) => {
        if (typeof text !== 'string') return '';
        
        return text.replace(/(\d+x\d*|\d*x\d+|(?<=\s)x(?=\s))/g, (match) => {
          let min, max;
          
          if (match === 'x') {
            min = 1;
            max = 40;
          } else if (match.startsWith('x')) {
            // x30 pattern
            max = parseInt(match.substring(1));
            min = 1;
          } else if (match.endsWith('x')) {
            // 20x pattern
            min = parseInt(match.substring(0, match.length - 1));
            max = min > 40 ? 2 * min : 40;
          } else {
            // 5x12 pattern
            const [before, after] = match.split('x').map(num => parseInt(num));
            min = before;
            max = after;
          }
          
          if (min > max) [min, max] = [max, min];
          
          let result = Math.floor(Math.random() * (max - min + 1)) + min;
          
          if (match === 'x' && result >= 10) {
            result = Math.round(result / 5) * 5;
          }
          
          return ` ${result} `;
        });
      };

      randomRewardText = randomizeNumbers(randomRewardText);

      const replacedText = replaceNames(randomRewardText, userNames.submissive.name, userNames.dominant.name);
      let [title, description] = replacedText.split(' - ');
      if (!description) {
        title = replacedText;
        description = replacedText;
      }

      const rewardData = {
        title: title.trim(),
        description: description.trim(),
        category: randomReward.category,
        points_required: generateRandomPoints(),
        require_proof: Math.random() < 0.5,
      };

      if (Math.random() < 0.5) {
        const dueDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
        rewardData.due_date = dueDate.toISOString().slice(0, 19).replace('T', ' ');
      }

      try {
        const response = await axiosInstance.post(`/rewards`, rewardData);
        if (response.status === 201 && response.data) {
          const createdReward = response.data;
          const assignResponse = await axiosInstance.post(`/rewards/${createdReward.id}/assign`);
          if (assignResponse.status === 200) {
            setPoints(assignResponse.data.new_points);
            await fetchRewards();
            rewardCreated = true;
          }
        } else {
          console.log('Unexpected response status or missing data. Trying again...');
        }
      } catch (error) {
        console.error('Error creating or assigning reward:', error);
        if (attempts === maxAttempts) {
          throw error;
        }
      }
    }

    if (!rewardCreated) {
      setErrorMessage('Unable to create a suitable reward after multiple attempts. Please try again.');
    }
  } catch (error) {
    console.error('Error in handleRandomReward:', error);
    setErrorMessage('An error occurred while creating a random reward. Please try again.');
  }
};

  const getFilteredExamples = useCallback(() => {
    let filteredExamples = examples.rewards.filter(example =>
      kinks.some(kink => kink.name.toLowerCase() === example.category.toLowerCase() && 
                 kink.submissive_rating !== 'hard no' && 
                 kink.dominant_rating !== 'hard no')
    );
    return filteredExamples.sort((a, b) => {
      const aKink = kinks.find(k => k.name.toLowerCase() === a.category.toLowerCase());
      const bKink = kinks.find(k => k.name.toLowerCase() === b.category.toLowerCase());
      const aRelevance = (aKink && aKink.dominant_rating === 'very much yes' && aKink.submissive_rating === 'please') ? 2 :
                         (aKink && (aKink.dominant_rating === 'yes' || aKink.submissive_rating === 'yes')) ? 1 : 0;
      const bRelevance = (bKink && bKink.dominant_rating === 'very much yes' && bKink.submissive_rating === 'please') ? 2 :
                         (bKink && (bKink.dominant_rating === 'yes' || bKink.submissive_rating === 'yes')) ? 1 : 0;
      return bRelevance - aRelevance;
    });
  }, [kinks]);

  const handleCompleteReward = async (rewardId, completionData) => {
    try {
      await axiosInstance.post(`/rewards/${rewardId}/complete`, completionData);
      fetchRewards();
      setShowCompletionModal(false);
    } catch (error) {
      setErrorMessage('Failed to complete reward. Please try again.');
    }
  };

  const sortRewards = (rewardsToSort) => {
    return rewardsToSort.sort((a, b) => {
      switch (sortBy) {
        case 'dateCreated':
          return new Date(b.created_at) - new Date(a.created_at);
        case 'points':
          return b.points_required - a.points_required;
        case 'name':
          return a.title.localeCompare(b.title);
        default:
          return 0;
      }
    });
  };

  const groupRewards = (rewards) => {
    if (groupBy === 'none') return { 'All Rewards': sortRewards(rewards) };
    
    return rewards.reduce((acc, reward) => {
      let key;
      if (groupBy === 'tag') {
        key = reward.category || 'Uncategorized';
      } else if (groupBy === 'status') {
        key = reward.assigned ? (reward.completed ? 'Completed' : 'Assigned') : 'Unassigned';
      }
      if (!acc[key]) acc[key] = [];
      acc[key].push(reward);
      return acc;
    }, {});
  };

  const groupedAndSortedRewards = Object.entries(groupRewards(rewards)).reduce((acc, [group, groupRewards]) => {
    acc[group] = sortRewards(groupRewards);
    return acc;
  }, {});

  const renderRewardItem = (reward) => (
    <Accordion key={reward.id}>
      <AccordionSummary 
        expandIcon={<ExpandMore />}
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        }}
      >
        <Typography sx={{ color: 'text.primary' }}>{reward.title}</Typography>
        <Box>
          {reward.require_proof && <Camera color="secondary" sx={{ mr: 1 }} />}
          {role === 'dominant' && (
            <>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                setSelectedReward(reward);
                setShowRewardModal(true);
              }}>
                <Edit />
              </IconButton>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                handleDeleteReward(reward.id);
              }}>
                <Delete />
              </IconButton>
              {!reward.assigned && (
                <IconButton onClick={(e) => {
                  e.stopPropagation();
                  handleAssignReward(reward.id);
                }}>
                  <Assignment />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">{reward.description}</Typography>
        <Typography variant="body2">Points Required: {reward.points_required}</Typography>
        <Typography variant="body2">
          Due Date: {reward.due_date ? new Date(reward.due_date).toLocaleString() : 'Not set'}
        </Typography>
        <Typography variant="body2">
          Requires Proof: {reward.require_proof ? 'Yes' : 'No'}
        </Typography>
        <Chip label={reward.category} size="small" sx={{ mt: 1 }} />
        {role === 'submissive' && reward.assigned && !reward.completed && (
          <Button onClick={() => {
            setSelectedReward(reward);
            setShowCompletionModal(true);
          }}>
            Complete
          </Button>
        )}
      </AccordionDetails>
    </Accordion>
  );

  const fetchWheelPunishments = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/wheel-punishments');
      if (Array.isArray(response.data) && response.data.length > 0) {
        setWheelPunishments(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setErrorMessage('Failed to fetch wheel punishments. Unexpected data format.');
      }
    } catch (error) {
      console.error('Error fetching wheel punishments:', error);
      setErrorMessage('Failed to fetch wheel punishments. Please try again.');
    }
  }, []);

  useEffect(() => {
    if (role === 'submissive' && rewards.length === 0) {
      console.log('Fetching wheel punishments...');
      fetchWheelPunishments();
    }
  }, [role, rewards.length, fetchWheelPunishments]);

const replaceNames = (text, submissiveName, dominantName) => {
  if (typeof text !== 'string') return '';
  
  const capitalizedSubmissive = submissiveName && typeof submissiveName === 'string' 
    ? submissiveName.charAt(0).toUpperCase() + submissiveName.slice(1) 
    : 'Submissive';
  
  const capitalizedDominant = dominantName && typeof dominantName === 'string'
    ? dominantName.charAt(0).toUpperCase() + dominantName.slice(1)
    : 'Dominant';

  return text
    .replace(/\b(submissive|Sub|sub|sub's)\b/g, (match) => {
      if (match === "sub's") return `${capitalizedSubmissive}'s`;
      return capitalizedSubmissive;
    })
    .replace(/\b(dom|dominant|Dom|Dom's|dom's)\b/g, (match) => {
      if (match === "Dom's" || match === "dom's") return `${capitalizedDominant}'s`;
      return capitalizedDominant;
    });
};

const randomizeNumbers = (text) => {
  
  if (typeof text !== 'string') return '';
  
  return text.replace(/(\d+x\d*|\d*x\d+|(?<=\s)x(?=\s))/g, (match) => {
    
    let min, max;
    
    // Handle the different patterns
    if (match === 'x') {
      min = 1;
      max = 40;
    } else if (match.startsWith('x')) {
      // x30 pattern
      max = parseInt(match.substring(1));
      min = 1;
    } else if (match.endsWith('x')) {
      // 20x pattern
      min = parseInt(match.substring(0, match.length - 1));
      max = min > 40 ? 2 * min : 40;
    } else {
      // 5x12 pattern
      const [before, after] = match.split('x').map(num => parseInt(num));
      min = before;
      max = after;
    }
    
    // Ensure min and max are in correct order
    if (min > max) [min, max] = [max, min];
    
    // Generate random number
    let result = Math.floor(Math.random() * (max - min + 1)) + min;
    
    // Round numbers >= 10 to nearest 5 if it's a simple 'x' pattern
    if (match === 'x' && result >= 10) {
      result = Math.round(result / 5) * 5;
    }
    
    return ` ${result} `;
  });
};

const modifyPunishmentContent = (punishment, userNames) => {
  console.log('modifyPunishmentContent input:', {
    punishment,
    userNames
  });

  if (!punishment || (typeof punishment.title !== 'string' && typeof punishment.text !== 'string')) {
    console.warn('Invalid punishment object:', punishment);
    return { ...punishment, title: 'Invalid Punishment' };
  }

  let title, description;
  if (punishment.title) {
    title = punishment.title;
    description = punishment.description || '';
  } else {
    const parts = punishment.text.split(' - ');
    title = parts[0].trim();
    description = parts.length > 1 ? parts.slice(1).join(' - ').trim() : '';
  }

  console.log('Split content:', { title, description });

  // First replace names
  let modifiedTitle = replaceNames(
    title,
    userNames?.submissive?.name || '',
    userNames?.dominant?.name || ''
  );
  console.log('After name replacement in title:', modifiedTitle);

  // Then randomize numbers
  modifiedTitle = randomizeNumbers(modifiedTitle);
  console.log('After number randomization in title:', modifiedTitle);

  let modifiedDescription = '';
  if (description) {
    modifiedDescription = replaceNames(
      description,
      userNames?.submissive?.name || '',
      userNames?.dominant?.name || ''
    );
    console.log('After name replacement in description:', modifiedDescription);

    modifiedDescription = randomizeNumbers(modifiedDescription);
    console.log('After number randomization in description:', modifiedDescription);
  }

  const result = { 
    ...punishment, 
    title: modifiedTitle, 
    description: modifiedDescription || modifiedTitle 
  };

  console.log('Final modified punishment:', result);
  return result;
};

const modifiedWheelPunishments = wheelPunishments.map(punishment => 
  modifyPunishmentContent(punishment, userNames)
);

const handleSpinTheWheel = async (selectedPunishment) => {
  console.log('handleSpinTheWheel input:', selectedPunishment);
  
  if (!selectedPunishment) {
    console.error('Invalid punishment selected:', selectedPunishment);
    setErrorMessage('Failed to spin the wheel. Invalid punishment selected.');
    return;
  }

  try {
    // Log the data being sent
    const requestData = {
      punishment_id: selectedPunishment.id,
      processed_title: selectedPunishment.title,
      processed_description: selectedPunishment.description,
      category: selectedPunishment.category || 'General' // Provide a default category if none exists
    };
    
    console.log('Sending to server:', requestData);

    const response = await axiosInstance.post('/spin-the-wheel', requestData);
    
    console.log('Server response:', response);

    if (response.status === 200) {
      setPoints(response.data.new_points);
      await fetchRewards();
      setErrorMessage('A new reward has been added based on your spin!');
    }
  } catch (error) {
    console.error('Error spinning the wheel:', error);
    setErrorMessage('Failed to spin the wheel. Please try again.');
  }
};

  if (isLoading) return <CircularProgress />;


return (
  <Container maxWidth={false} disableGutters>
    <Snackbar
      open={!!errorMessage}
      autoHideDuration={6000}
      onClose={() => setErrorMessage('')}
      message={errorMessage}
    />
    <Card sx={{ mt: 2, mb: 2 }}>
      <CardContent sx={{ p: 0 }}>
        <Typography variant="h6" sx={{ p: 2, pb: 1 }}>Rewards</Typography>
        {role === 'dominant' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenConfirmDialog(true)}
            sx={{ ml: 2, mb: 1 }}
          >
            Remove All Rewards
          </Button>
        )}
          {rewards.length === 0 && role === 'submissive' ? (
            <Box sx={{ p: 2 }}>
              {points >= 500 && modifiedWheelPunishments.length > 0 ? (
                <WheelOfPrizes 
                  segments={modifiedWheelPunishments.map(p => p.title)} 
                  onFinished={(selectedSegment) => {
                    const selectedPunishment = modifiedWheelPunishments.find(p => p.title === selectedSegment);
                    handleSpinTheWheel(selectedPunishment);
                  }}
                />
              ) : (
                <Typography variant="body1" sx={{ textAlign: 'center', py: 4 }}>
                  {points < 500 
                    ? `No rewards yet, either make at least 500 points to spin the wheel or hope that ${userNames.dominant?.name || 'your Dominant'} is feeling generous`
                    : 'No rewards available at the moment.'}
                </Typography>
              )}
            </Box>
          ) : (
          Object.entries(groupedAndSortedRewards).map(([group, groupRewards]) => (
            <Accordion 
              key={group} 
              defaultExpanded 
              sx={{ 
                '&.MuiAccordion-root': { 
                  padding: 0,
                  '&:before': {
                    display: 'none',
                  },
                },
                '&.MuiPaper-root': {
                  boxShadow: 'none',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                },
              }}
            >
              <AccordionSummary 
                expandIcon={<ExpandMore />}
                sx={{ 
                  '&.MuiAccordionSummary-root': { 
                    padding: '0 8px',
                    minHeight: 48,
                    '&.Mui-expanded': {
                      minHeight: 48,
                    },
                  },
                  '& .MuiAccordionSummary-content': { 
                    margin: '12px 0',
                    '&.Mui-expanded': {
                      margin: '12px 0',
                    },
                  }
                }}
              >
                <Typography>{group}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                {groupRewards.length === 0 ? (
                  <Typography variant="body1" sx={{ p: 2 }}>No rewards in this group.</Typography>
                ) : (
                  groupRewards.map(renderRewardItem)
                )}
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </CardContent>
    </Card>
      <Dialog
  open={deleteDialogOpen}
  onClose={() => setDeleteDialogOpen(false)}
>
  <DialogTitle>Confirm Deletion</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to delete this reward?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={confirmDeleteReward} color="secondary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Remove All Rewards</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove all rewards? This action will unassign all rewards and return the points to the submissive.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleRemoveAllRewards} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showRewardModal} onClose={() => setShowRewardModal(false)}>
        <DialogTitle>{selectedReward ? 'Edit Reward' : 'Create New Reward'}</DialogTitle>
        <DialogContent>
          <RewardForm
            onSubmit={selectedReward ? handleEditReward : handleCreateReward}
            onClose={() => setShowRewardModal(false)}
            reward={selectedReward}
            kinks={kinks}
            userNames={{
              submissive: { username: userNames.submissive?.name || '' },
              dominant: { username: userNames.dominant?.name || '' }
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={showCompletionModal} onClose={() => setShowCompletionModal(false)}>
        <DialogTitle>Complete Reward</DialogTitle>
        <DialogContent>
          <CompletionForm
            onSubmit={(completionData) => handleCompleteReward(selectedReward.id, completionData)}
            onClose={() => setShowCompletionModal(false)}
          />
        </DialogContent>
      </Dialog>

      {role === 'dominant' && (
        <>
          <Fab 
            color="primary" 
            aria-label="add" 
            sx={{ position: 'fixed', bottom: 70, right: 16 }} 
            onClick={() => {
              setSelectedReward(null);
              setShowRewardModal(true);
            }}
          >
            <Add />
          </Fab>
          <Fab 
            color="secondary" 
            aria-label="random" 
            sx={{ position: 'fixed', bottom: 70, right: 80 }} 
            onClick={handleRandomReward}
          >
            <Casino />
          </Fab>
        </>
      )}
    </Container>
  );
};

export default Rewards;

export {
  RewardForm,
  CompletionForm,
  POINT_PRESETS,
};