import { axiosInstance } from './auth';

export const fetchWelcomeMessage = async () => {
  try {
    const response = await axiosInstance.get(`/welcome-message`);
    return response.data.message;
  } catch (error) {
    console.error('Error fetching welcome message:', error);
    throw error;
  }
};

export const updateWelcomeMessage = async (newMessage) => {
  try {
    const response = await axiosInstance.put(`/welcome-message`, { message: newMessage });
    return response.data.message;
  } catch (error) {
    console.error('Error updating welcome message:', error);
    throw error;
  }
};
