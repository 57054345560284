import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Button, Dialog, DialogTitle, DialogContent,
  TextField, CircularProgress, Snackbar, Fab, Card, CardContent,
  Box, Select, MenuItem, FormControl, InputLabel, Chip,
  Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText,
  Tooltip, IconButton, ListItemSecondaryAction, useTheme, useMediaQuery,
  Grid
} from '@mui/material';
import {
  Add, ExpandMore, Edit, Delete, CheckCircle, Cancel, CalendarToday, Warning
} from '@mui/icons-material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip as RechartsTooltip } from 'recharts';
import { examples } from './examples';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const RuleForm = ({ onSubmit, rule = null, onClose, kinks = [], userNames }) => {
  const [text, setText] = useState(rule ? rule.text : '');
  const [category, setCategory] = useState(rule ? rule.category : '');
  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      text,
      category,
      id: rule ? rule.id : undefined
    });
    onClose();
  };

  const getFilteredExamples = useCallback(() => {
    if (!examples || !examples.rules || !Array.isArray(examples.rules)) {
      console.error('Invalid or missing rules in examples.js');
      return [];
    }

    let filteredExamples = examples.rules.filter(example =>
      kinks.some(kink => kink.name.toLowerCase() === example.category.toLowerCase() && 
                 kink.submissive_rating !== 'hard no' && 
                 kink.dominant_rating !== 'hard no')
    );

    return filteredExamples.sort((a, b) => {
      const aKink = kinks.find(k => k.name.toLowerCase() === a.category.toLowerCase());
      const bKink = kinks.find(k => k.name.toLowerCase() === b.category.toLowerCase());
      const aRelevance = (aKink && aKink.dominant_rating === 'very much yes' && aKink.submissive_rating === 'please') ? 2 :
                         (aKink && (aKink.dominant_rating === 'yes' || aKink.submissive_rating === 'yes')) ? 1 : 0;
      const bRelevance = (bKink && bKink.dominant_rating === 'very much yes' && bKink.submissive_rating === 'please') ? 2 :
                         (bKink && (bKink.dominant_rating === 'yes' || bKink.submissive_rating === 'yes')) ? 1 : 0;
      return bRelevance - aRelevance;
    });
  }, [kinks]);

  const filteredExamples = getFilteredExamples();

  const handleExampleClick = (example) => {
    setText(example.text);
    setCategory(example.category);
  };

  const getKinkAlignment = (kink) => {
    if (kink.dominant_rating === 'very much yes' && kink.submissive_rating === 'please') return theme.palette.success.main;
    if (kink.dominant_rating === 'yes' && kink.submissive_rating === 'yes') return theme.palette.success.light;
    if (kink.dominant_rating === 'maybe' && kink.submissive_rating === 'maybe') return theme.palette.warning.main;
    return 'inherit';
  };

  const getKinkEmoji = (rating) => {
    switch (rating) {
      case 'hard no': return '🚫';
      case 'if forced': return '😬';
      case 'maybe': return '🤔';
      case 'yes': return '😊';
      case 'please': return '😍';
      case 'very much yes': return '🥰';
      default: return '❓';
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Example Rules</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            {kinks.filter(kink => 
              kink.dominant_rating !== 'hard no' && 
              kink.submissive_rating !== 'hard no' &&
              filteredExamples.some(example => example.category.toLowerCase() === kink.name.toLowerCase())
            ).map((kink) => (
              <Accordion key={kink.name}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography sx={{ flexGrow: 1, color: getKinkAlignment(kink) }}>{kink.name}</Typography>
                    <Tooltip title={`${userNames.dominant.username}: ${kink.dominant_rating}, ${userNames.submissive.username}: ${kink.submissive_rating}`}>
                      <Typography>
                        {getKinkEmoji(kink.dominant_rating)} {getKinkEmoji(kink.submissive_rating)}
                      </Typography>
                    </Tooltip>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {filteredExamples
                      .filter(example => example.category.toLowerCase() === kink.name.toLowerCase())
                      .map((example, index) => (
                        <ListItem key={index} button onClick={() => handleExampleClick(example)}>
                          <ListItemText primary={example.text} />
                        </ListItem>
                      ))
                    }
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <TextField
        fullWidth
        label="Rule Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        margin="normal"
        required
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Category</InputLabel>
        <Select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          label="Category"
        >
          <MenuItem value="Custom">Custom</MenuItem>
          {kinks.map((kink) => (
            <MenuItem key={kink.name} value={kink.name}>{kink.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        {rule ? 'Update Rule' : 'Create Rule'}
      </Button>
    </form>
  );
};

const Rules = ({ role, sortBy, filterBy }) => {
  const [rules, setRules] = useState([]);
  const [sortedAndFilteredRules, setSortedAndFilteredRules] = useState([]);
  const [kinks, setKinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showRuleModal, setShowRuleModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [userNames, setUserNames] = useState({ submissive: '', dominant: '' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [rulesResponse, kinksResponse, userNamesResponse] = await Promise.all([
        axiosInstance.get(`/rules`),
        axiosInstance.get(`/kinks`),
        axiosInstance.get('/user-names')
      ]);
      setRules(rulesResponse.data || []);
      setKinks(kinksResponse.data || []);
      setUserNames(userNamesResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setErrorMessage('One or more endpoints not found. Please check server configuration.');
      } else {
        setErrorMessage('Failed to fetch data. Please try again.');
      }
      setRules([]);
      setKinks([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let filteredRules = rules;

    // Apply filtering
    if (filterBy === 'accepted') {
      filteredRules = rules.filter(rule => rule.accepted);
    } else if (filterBy === 'not_accepted') {
      filteredRules = rules.filter(rule => !rule.accepted);
    }

    // Apply sorting
    const sortedRules = [...filteredRules].sort((a, b) => {
      if (sortBy === 'dateCreated') {
        return new Date(b.created_at) - new Date(a.created_at);
      } else if (sortBy === 'name') {
        return a.text.localeCompare(b.text);
      }
      return 0;
    });

    setSortedAndFilteredRules(sortedRules);
  }, [rules, sortBy, filterBy]);

  const handleCreateOrEditRule = async (ruleData) => {
    try {
      let response;
      if (ruleData.id) {
        response = await axiosInstance.put(`/rules`, ruleData);
      } else {
        response = await axiosInstance.post(`/rules`, ruleData);
      }
      if (response.status === 200 || response.status === 201) {
        fetchData();
        setShowRuleModal(false);
        setSelectedRule(null);
        setErrorMessage('');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error creating/editing rule:', error);
      setErrorMessage('Failed to create/edit rule. Please try again.');
    }
  };

  const handleAcceptRule = async (ruleId) => {
    try {
      const response = await axiosInstance.put(`/rules`, { id: ruleId, accepted: true });
      if (response.status === 200) {
        fetchData();
        setErrorMessage('');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error accepting rule:', error);
      setErrorMessage('Failed to accept rule. Please try again.');
    }
  };

  const handleDeleteRule = async (ruleId) => {
    try {
      const response = await axiosInstance.delete(`/rules/${ruleId}`);
      if (response.status === 200) {
        fetchData();
        setErrorMessage('');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error deleting rule:', error);
      setErrorMessage('Failed to delete rule. Please try again.');
    }
  };

  const getKinkAlignment = (kink) => {
    if (kink.dominant_rating === 'very much yes' && kink.submissive_rating === 'please') return theme.palette.success.main;
    if (kink.dominant_rating === 'yes' && kink.submissive_rating === 'yes') return theme.palette.success.light;
    if (kink.dominant_rating === 'maybe' && kink.submissive_rating === 'maybe') return theme.palette.warning.main;
    return theme.palette.background.paper;
  };

  const getDaysSinceCreation = (createdAt) => {
    const creationDate = new Date(createdAt);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - creationDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  if (isLoading) return <CircularProgress />;

  if (rules.length === 0) {
    return (
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>Rules</Typography>
        <Card>
          <CardContent>
            <Typography variant="body1" align="center">
              {role === 'dominant' 
                ? "No rules created yet. Touch the + button to create a new rule or browse the examples."
                : `No rules created yet. You'll need to wait for ${userNames.dominant.username} to do that.`
              }
            </Typography>
          </CardContent>
        </Card>

        {role === 'dominant' && (
          <Fab 
            color="primary" 
            aria-label="add" 
            sx={{ 
              position: 'fixed', 
              bottom: isMobile ? 80 : 16,
              right: 16,
              zIndex: (theme) => theme.zIndex.drawer + 2
            }}
            onClick={() => setShowRuleModal(true)}
          >
            <Add />
          </Fab>
        )}

        <Dialog open={showRuleModal} onClose={() => setShowRuleModal(false)}>
          <DialogTitle>Create New Rule</DialogTitle>
          <DialogContent>
            <RuleForm
              onSubmit={handleCreateOrEditRule}
              onClose={() => setShowRuleModal(false)}
              kinks={kinks}
              userNames={userNames}
            />
          </DialogContent>
        </Dialog>

        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
          message={errorMessage}
        />
      </Container>
    );
  }

  const renderRuleItem = (rule) => {
    const kink = kinks.find(k => k.name === rule.category);
    const backgroundColor = kink ? getKinkAlignment(kink) : theme.palette.background.paper;
    const daysSinceCreation = getDaysSinceCreation(rule.created_at);

    return (
      <ListItem
        key={rule.id}
        sx={{
          mb: 1,
          borderRadius: 1,
          backgroundColor,
          '&:hover': { backgroundColor: theme.palette.action.hover },
        }}
      >
        <ListItemText
          primary={rule.text}
          secondary={
            <React.Fragment>
              <Chip label={rule.category} size="small" sx={{ mr: 1 }} />
              {rule.accepted ? (
                <Tooltip title={`Accepted on ${new Date(rule.accepted_date).toLocaleDateString()}`}>
                  <Chip icon={<CalendarToday />} label="Accepted" size="small" color="success" />
                </Tooltip>
              ) : (
                <Tooltip title={`Created ${daysSinceCreation} days ago`}>
                  <Chip icon={<Warning />} label={`Not accepted (${daysSinceCreation} days)`} size="small" color="warning" />
                </Tooltip>
              )}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          {role === 'dominant' ? (
            <React.Fragment>
              <IconButton edge="end" aria-label="edit" onClick={() => {
                setSelectedRule(rule);
                setShowRuleModal(true);
              }}>
                <Edit />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteRule(rule.id)}>
                <Delete />
              </IconButton>
            </React.Fragment>
          ) : (
            !rule.accepted && (
              <IconButton edge="end" aria-label="accept" onClick={() => handleAcceptRule(rule.id)}>
                <CheckCircle />
              </IconButton>
            )
          )}
          <Tooltip title={rule.accepted ? "Accepted" : "Not accepted"}>
            <IconButton edge="end" aria-label="status">
              {rule.accepted ? <CheckCircle color="success" /> : <Cancel color="error" />}
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderCategoryPieChart = () => {
    const data = kinks.map(kink => ({
      name: kink.name,
      value: rules.filter(rule => rule.category === kink.name).length
    })).filter(item => item.value > 0);

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend />
          <RechartsTooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const renderAcceptedPieChart = () => {
    const acceptedRules = rules.filter(rule => rule.accepted).length;
    const notAcceptedRules = rules.filter(rule => !rule.accepted).length;

    if (acceptedRules === rules.length) {
      return null;  // Dont render the chart if all rules are accepted
    }

    const data = [
      { name: 'Accepted', value: acceptedRules },
      { name: 'Not Accepted', value: notAcceptedRules }
    ];

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend />
          <RechartsTooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  if (isLoading) return <CircularProgress />;

  const acceptedChart = renderAcceptedPieChart();

  return (
    <Container maxWidth="lg" sx={{ px: { xs: 1, sm: 2, md: 3 }, overflowX: 'hidden' }}>
      <Typography variant="h4" gutterBottom>Rules</Typography>
      
      <Card sx={{ mb: 2, overflowX: 'hidden' }}>
        <CardContent>
          <Grid container spacing={2} sx={{ width: '100%', margin: 0 }}>
            <Grid item xs={12} md={acceptedChart ? 6 : 12}>
              <Typography variant="h6" gutterBottom>Rules by Category</Typography>
              <Box sx={{ height: 300, width: '100%' }}>
                {renderCategoryPieChart()}
              </Box>
            </Grid>
            {acceptedChart && (
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>Rules Accepted</Typography>
                <Box sx={{ height: 300, width: '100%' }}>
                  {acceptedChart}
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ overflowX: 'hidden' }}>
        <CardContent>
          <List>
            {sortedAndFilteredRules
              .filter(rule => rule && typeof rule === 'object' && rule.id !== undefined && rule.id !== null)
              .map(renderRuleItem)}
          </List>
        </CardContent>
      </Card>

      <Dialog open={showRuleModal} onClose={() => {
        setShowRuleModal(false);
        setSelectedRule(null);
      }}>
        <DialogTitle>{selectedRule ? 'Edit Rule' : 'Create New Rule'}</DialogTitle>
        <DialogContent>
          <RuleForm
            onSubmit={handleCreateOrEditRule}
            onClose={() => {
              setShowRuleModal(false);
              setSelectedRule(null);
            }}
            rule={selectedRule}
            kinks={kinks}
            userNames={userNames}
          />
        </DialogContent>
      </Dialog>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />

      {role === 'dominant' && (
        <Fab 
          color="primary" 
          aria-label="add" 
          sx={{ 
            position: 'fixed', 
            bottom: isMobile ? 80 : 16,
            right: 16,
            zIndex: (theme) => theme.zIndex.drawer + 2
          }}
          onClick={() => setShowRuleModal(true)}
        >
          <Add />
        </Fab>
      )}
    </Container>
  );
};

export default Rules;