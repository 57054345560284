import React, { useState, useEffect } from 'react';
import { axiosInstance } from './auth';
import { Container, Tabs, Tab, Box } from '@mui/material';
import Tasks from './tasks';
import Completions from './completions';
import SpotChecks from './spotChecks';

const TasksAndCompletions = ({ role, points, setPoints }) => {
  const [tabValue, setTabValue] = useState(0);
  const [kinks, setKinks] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchKinks = async () => {
      try {
        const response = await axiosInstance.get(`/kinks`);
        setKinks(response.data);
      } catch (error) {
        console.error('Error fetching kinks:', error);
      }
    };
    fetchKinks();
  }, []);

  return (
    <Container maxWidth={false} sx={{ px: '0px' }}>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Tasks" />
        {role === 'dominant' && <Tab label="Completions" />}
        {role === 'dominant' && <Tab label="Spot Checks" />}
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {tabValue === 0 && (
          <Tasks role={role} points={points} setPoints={setPoints} kinks={kinks} />
        )}
        {tabValue === 1 && role === 'dominant' && <Completions role={role} setPoints={setPoints} />}
        {tabValue === 2 && role === 'dominant' && <SpotChecks role={role} />}
      </Box>
    </Container>
  );
};

export default TasksAndCompletions;
