import axios from 'axios';
import config from './config';
import { useEffect } from 'react';

// Create a custom axios instance
const axiosInstance = axios.create({
  baseURL: config.API_BASE_URL,
  timeout: 3000,
});

export function isTokenExpired(token) {
  try {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    return decodedToken.exp * 1000 < Date.now();
  } catch (error) {
    console.error("Invalid token format", error);
    return true; // Treat invalid tokens as expired
  }
}

// Create a separate axios instance for video streaming that won't timeout as quickly
const videoAxiosInstance = axios.create({
  baseURL: config.API_BASE_URL,
  timeout: 0, // No timeout for video streaming
  responseType: 'blob', // For handling video streams
});

// Add the same auth interceptor to the video instance
videoAxiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle video streaming
videoAxiosInstance.interceptors.response.use(
  (response) => {
    // If it's a video request, return the raw response
    if (response.config.url.includes('/proxy-video')) {
      return response;
    }
    return response;
  },
  (error) => Promise.reject(error)
);

// Function to handle authentication state and token checks
export function useAuthCheck() {
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    const currentPath = window.location.pathname;

    // Check if token is missing or expired
    if (!token || isTokenExpired(token)) {
      // Redirect to login if not already on the login page or root path
      if (currentPath !== '/login' && currentPath !== '/') {
        window.location.href = '/';
      }
    }

    // Listen for service worker messages about token expiration
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'TOKEN_EXPIRED') {
          if (currentPath !== '/login' && currentPath !== '/') {
            console.warn('Service worker detected token expiration, redirecting to login');
            window.location.href = '/';
          }
        }
      });
    }
  }, []); // Only run on mount
}

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Clear local storage
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export const login = async (username, password) => {
  try {
    const response = await axiosInstance.post('/login', { username, password });
    console.log('Login response:', response.data);
    if (!response.data.access_token || !response.data.role) {
      throw new Error('Invalid response from server');
    }
    localStorage.setItem('token', response.data.access_token);
    localStorage.setItem('role', response.data.role);
    
    return response.data.role;
  } catch (error) {
    console.error('Login failed:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

export const getRole = () => {
  return localStorage.getItem('role');
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const refreshToken = async () => {
  try {
    const response = await axiosInstance.post('/refresh-token', {
      token: getToken()
    });
    localStorage.setItem('token', response.data.access_token);
    return response.data.access_token;
  } catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
  }
};

// Add a request interceptor to include the JWT token in all requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const registerAccount = async (username, email, password) => {
  try {
    const response = await axiosInstance.post('/register', {
      username,
      email,
      password
    });
    return { ...response.data, requiresLogin: true };
  } catch (error) {
    console.error('Registration failed:', error);
    throw error;
  }
};

// Placeholder function for Google login
export const loginWithGoogle = async () => {
  try {
    const response = await axiosInstance.post('/login-with-google');
    return { success: true, message: 'Google login attempt logged' };
  } catch (error) {
    console.error('Google login failed:', error);
    throw error;
  }
};

const getProxiedVideo = async (videoUrl) => {
  try {
    const response = await videoAxiosInstance.get(`/proxy-video?url=${encodeURIComponent(videoUrl)}`, {
      responseType: 'blob'
    });
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error fetching video:', error);
    throw error;
  }
};

// Export the custom axios instance
export { axiosInstance, videoAxiosInstance, getProxiedVideo };
