import React, { useState, useEffect } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, TextField, Button, Snackbar, Box, List, ListItem, ListItemText,
  Tooltip, IconButton
} from '@mui/material';
import { Event, EmojiEvents, Refresh, School } from '@mui/icons-material';

const SpecialDays = ({ role }) => {
  const [specialDays, setSpecialDays] = useState([]);
  const [newSpecialDay, setNewSpecialDay] = useState({
    name: '',
    date: '',
    points: 0
  });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchSpecialDays();
  }, []);

  const fetchSpecialDays = async () => {
    try {
      const response = await axiosInstance.get(`/special-days`);
      setSpecialDays(response.data);
    } catch (error) {
      setErrorMessage('Failed to fetch special days. Please try again.');
    }
  };

  const handleAddSpecialDay = async () => {
    try {
      await axiosInstance.post(`/special-days`, newSpecialDay);
      setNewSpecialDay({ name: '', date: '', points: 0 });
      fetchSpecialDays();
    } catch (error) {
      setErrorMessage('Failed to add special day. Please try again.');
    }
  };

  const handleEnableTutorials = async () => {
    try {
      await axiosInstance.post('/enable-tutorials');
      setErrorMessage('Tutorials have been re-enabled. They will show on your next login.');
    } catch (error) {
      console.error('Failed to enable tutorials:', error);
      setErrorMessage('Failed to enable tutorials. Please try again.');
    }
  };

  const handleEnableFirstLoginWizard = async () => {
    try {
      await axiosInstance.post('/enable-first-login-wizard');
      setErrorMessage('First Login Wizard has been re-enabled. It will show on your next login.');
    } catch (error) {
      console.error('Failed to enable First Login Wizard:', error);
      setErrorMessage('Failed to enable First Login Wizard. Please try again.');
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Special Days and Settings</Typography>
      
      {role === 'dominant' && (
        <Box mb={2}>
          <Typography variant="h6">Add Special Day</Typography>
          <TextField
            value={newSpecialDay.name}
            onChange={(e) => setNewSpecialDay({...newSpecialDay, name: e.target.value})}
            label="Name"
            fullWidth
            margin="normal"
          />
          <TextField
            type="date"
            value={newSpecialDay.date}
            onChange={(e) => setNewSpecialDay({...newSpecialDay, date: e.target.value})}
            label="Date"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            type="number"
            value={newSpecialDay.points}
            onChange={(e) => setNewSpecialDay({...newSpecialDay, points: parseInt(e.target.value)})}
            label="Points"
            fullWidth
            margin="normal"
          />
          <Button onClick={handleAddSpecialDay} variant="contained" color="primary" startIcon={<Event />}>
            Add Special Day
          </Button>
        </Box>
      )}

      <Box mb={2}>
        <Typography variant="h6">Tutorial Settings</Typography>
        <Button onClick={handleEnableTutorials} variant="outlined" color="primary" startIcon={<Refresh />} sx={{ mr: 1 }}>
          Re-enable Tutorials
        </Button>
        <Button onClick={handleEnableFirstLoginWizard} variant="outlined" color="primary" startIcon={<School />}>
          Re-enable First Login Wizard
        </Button>
      </Box>

      <Typography variant="h6">Special Days</Typography>
      {specialDays.length === 0 && (
        <Typography variant="body1">No special days have been set.</Typography>
      )}
      <List>
        {specialDays.map((day) => (
          <ListItem key={day.id}>
            <ListItemText 
              primary={day.name} 
              secondary={`Date: ${day.date}, Points: ${day.points}`} 
            />
            <Tooltip title={`${day.points} points`}>
              <IconButton>
                <EmojiEvents color="primary" />
              </IconButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
    </Container>
  );
};

export default SpecialDays;