import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import './starfield.css';

const CenteredLoading = () => {
  const numberOfStars = 200;
  const starsArray = Array.from({ length: numberOfStars });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent to show starfield
        zIndex: 9999,
      }}
    >
      <div className="starfield">
        {starsArray.map((_, index) => (
          <div
            key={index}
            className="stars"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDuration: `${Math.random() * 5 + 4}s`, // Random animation duration between 4 and 9 seconds
            }}
          />
        ))}
      </div>
      <CircularProgress size={150} thickness={10} />
    </Box>
  );
};

export default CenteredLoading;