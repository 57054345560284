// UserProfile.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Select, MenuItem, CircularProgress, Snackbar } from '@mui/material';
import { axiosInstance } from './auth';

const UserProfile = ({ onComplete }) => {
  const [profile, setProfile] = useState({
    username: '',
    email: '',
    pronouns: '',
    gender: '',
    sex: '',
    user_type: '',
  });
  const [showOtherGender, setShowOtherGender] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axiosInstance.get('/user-profile');
        setProfile(response.data);
        if (response.data.gender && !['male', 'female', 'nonbinary', 'genderfluid', 'rather_not_say'].includes(response.data.gender)) {
          setShowOtherGender(true);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setError('Failed to fetch user profile');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    try {
      await axiosInstance.put('/user-profile', { [name]: value });
      setProfile({ ...profile, [name]: value });
      setSuccess('Profile updated successfully');
      onComplete({ ...profile, [name]: value });
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError('Failed to update user profile');
    }
  };

  const handleGenderChange = async (e) => {
    const value = e.target.value;
    if (value === 'other') {
      setShowOtherGender(true);
      setProfile({ ...profile, gender: '' });
    } else {
      setShowOtherGender(false);
      try {
        await axiosInstance.put('/user-profile', { gender: value });
        setProfile({ ...profile, gender: value });
        setSuccess('Profile updated successfully');
        onComplete({ ...profile, gender: value });
      } catch (error) {
        console.error('Error updating user profile:', error);
        setError('Failed to update user profile');
      }
    }
  };

  const handleOtherGenderChange = async (e) => {
    const value = e.target.value;
    try {
      await axiosInstance.put('/user-profile', { gender: value });
      setProfile({ ...profile, gender: value });
      setSuccess('Profile updated successfully');
      onComplete({ ...profile, gender: value });
    } catch (error) {
      console.error('Error updating user profile:', error);
      setError('Failed to update user profile');
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="h6">User Profile</Typography>
      <TextField
        name="username"
        label="Username"
        value={profile.username}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        name="email"
        label="Email"
        type="email"
        value={profile.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        name="pronouns"
        label="Pronouns"
        value={profile.pronouns}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Select
        name="gender"
        value={showOtherGender ? 'other' : profile.gender}
        onChange={handleGenderChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="male">Male</MenuItem>
        <MenuItem value="female">Female</MenuItem>
        <MenuItem value="nonbinary">Nonbinary</MenuItem>
        <MenuItem value="genderfluid">Genderfluid</MenuItem>
        <MenuItem value="other">Other: Specify</MenuItem>
        <MenuItem value="rather_not_say">Rather Not Say</MenuItem>
      </Select>
      {showOtherGender && (
        <TextField
          name="otherGender"
          value={profile.gender}
          onChange={handleOtherGenderChange}
          placeholder="Specify your gender"
          fullWidth
          margin="normal"
        />
      )}
      <Select
        name="sex"
        value={profile.sex}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="afab">AFAB</MenuItem>
        <MenuItem value="amab">AMAB</MenuItem>
        <MenuItem value="MTF">MTF</MenuItem>
        <MenuItem value="FTM">FTM</MenuItem>
        <MenuItem value="intersex">Intersex</MenuItem>
        <MenuItem value="indeterminate">Indeterminate</MenuItem>
      </Select>
      <Select
        name="user_type"
        value={profile.user_type}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      >
        <MenuItem value="dominant">Dominant</MenuItem>
        <MenuItem value="submissive">Submissive</MenuItem>
      </Select>
      <Snackbar
        open={!!error || !!success}
        autoHideDuration={6000}
        onClose={() => {
          setError('');
          setSuccess('');
        }}
        message={error || success}
      />
    </Box>
  );
};

export default UserProfile;