import React, { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from './auth';
import {
  Container, Typography, Button, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, CircularProgress, Snackbar, Fab,
  Box, Select, MenuItem, FormControl, InputLabel, Chip, Accordion,
  AccordionSummary, AccordionDetails, Switch, FormControlLabel, List, ListItem, ListItemText,
  IconButton, Collapse, Tooltip, Rating
} from '@mui/material';
import {
  Add, ExpandMore, PlayArrow, Pause, Stop, Edit as EditIcon, Delete as DeleteIcon,
  Assignment as AssignIcon, ExpandLess as ExpandLessIcon, 
  LocationOn, AutorenewRounded, CheckCircleOutline
} from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { examples } from './examples';

const POINT_PRESETS = [1, 5, 10, 50, 100];

const getTaskStatusColor = (status) => {
  switch (status) {
    case 'incomplete':
      return 'error.main';
    case 'submitted':
      return 'warning.main';
    case 'approved':
      return 'success.main';
    case 'pending_approval':
      return 'info.main';
    default:
      return 'text.primary';
  }
};

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const Timer = ({ onTimerUpdate }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const intervalRef = React.useRef(null);

  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const pauseTimer = () => {
    if (isRunning) {
      clearInterval(intervalRef.current);
      setIsRunning(false);
    }
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
    onTimerUpdate(time);
    setTime(0);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Timer: {formatTime(time)}</Typography>
      <Button startIcon={<PlayArrow />} onClick={startTimer} disabled={isRunning}>Start</Button>
      <Button startIcon={<Pause />} onClick={pauseTimer} disabled={!isRunning}>Pause</Button>
      <Button startIcon={<Stop />} onClick={stopTimer}>Stop</Button>
    </Box>
  );
};

const CompletionDots = ({ completionHistory = [] }) => {
  // Ensure we have exactly 10 items, pad with null for missing entries
  const history = Array(10).fill(null).map((_, i) => completionHistory[i] || null);

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'success.main';
      case 'denied':
        return 'error.main';
      case 'pending':
        return 'warning.main';
      default:
        return 'transparent';
    }
  };

  const getTooltipContent = (completion) => {
    if (!completion) return 'No completion data';
    
    const date = new Date(completion.date).toLocaleDateString();
    const status = completion.status.charAt(0).toUpperCase() + completion.status.slice(1);
    const points = completion.points_awarded ? ` (${completion.points_awarded} points)` : '';
    
    return `${status} on ${date}${points}`;
  };

  return (
    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', my: 0.5 }}>
      {history.map((completion, index) => (
        <Tooltip
          key={index}
          title={getTooltipContent(completion)}
          arrow
        >
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              bgcolor: completion ? getStatusColor(completion.status) : 'transparent',
              border: '1px solid',
              borderColor: completion ? getStatusColor(completion.status) : 'text.disabled',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.2)',
                boxShadow: 1
              }
            }}
          />
        </Tooltip>
      ))}
    </Box>
  );
};

const TaskForm = ({ onSubmit, task, onClose, role, kinks = [], onApprove, onDeny }) => {
  const [title, setTitle] = useState(task?.title || '');
  const [description, setDescription] = useState(task?.description || '');
  const [pointsComplete, setPointsComplete] = useState(task?.points_complete || POINT_PRESETS[0]);
  const [pointsIncomplete, setPointsIncomplete] = useState(task?.points_incomplete || POINT_PRESETS[0]);
  const [isRecurring, setIsRecurring] = useState(task?.is_recurring || false);
  const [recurrencePattern, setRecurrencePattern] = useState(task?.recurrence_pattern || 'none');
  const [weekDay, setWeekDay] = useState(task?.week_day || 'monday');
  const [dueDate, setDueDate] = useState(task?.due_date ? new Date(task.due_date) : null);
  const [requireProof, setRequireProof] = useState(task?.require_proof || false);
  const [sendNotification, setSendNotification] = useState(task?.send_notification || false);
  const [tags, setTags] = useState(task?.tags || []);
  const [tagInput, setTagInput] = useState('');
  const [gpsCheckIn, setGpsCheckIn] = useState(task?.gps_check_in || false);
  const [submissiveCanComplete, setSubmissiveCanComplete] = useState(task?.submissive_can_complete || false);
  const [customRecurrence, setCustomRecurrence] = useState(task?.custom_recurrence || {
    type: 'days',
    interval: 1,
    weekDays: [],
    monthDays: [],
  });

  const recurrenceOptions = [
    { value: 'none', label: 'None' },
    { value: 'daily', label: 'Daily' }, 
    { value: 'weekdays', label: 'Weekdays (Mon-Fri)' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'bi-weekly', label: 'Bi-Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'custom', label: 'Custom' }
  ];
  
  const weekDays = [
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
  ];

  const handleTagInputChange = (e) => {
    const value = e.target.value;
    setTagInput(value);
    if (value.endsWith(' ')) {
      const newTag = value.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setTagInput('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      id: task?.id,
      title,
      description,
      points_complete: role === 'dominant' ? pointsComplete : 10,
      points_incomplete: role === 'dominant' ? pointsIncomplete : 10,
      is_recurring: isRecurring,
      recurrence_pattern: recurrencePattern,
      week_day: weekDay,
      due_date: isRecurring ? 
        (recurrencePattern === 'custom' && customRecurrence.type === 'months' ? 
          dueDate?.toISOString() : null) 
        : dueDate?.toISOString(),
      require_proof: requireProof,
      send_notification: sendNotification,
      tags: tags,
      gps_check_in: gpsCheckIn,
      submissive_can_complete: submissiveCanComplete,
      custom_recurrence: recurrencePattern === 'custom' ? {
        ...customRecurrence,
        monthDays: customRecurrence.type === 'months' ? undefined : customRecurrence.monthDays,
        start_date: customRecurrence.type === 'months' ? dueDate?.toISOString() : undefined
      } : null
    });
    onClose();
  };

  const renderActionButtons = () => {
    if (task && task.status === 'pending_approval' && role === 'dominant') {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => onDeny && onDeny(task.id)}
          >
            Deny
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => onApprove && onApprove(task.id)}
          >
            Approve
          </Button>
        </Box>
      );
    } else {
      return (
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          {task ? 'Update Task' : (role === 'dominant' ? 'Create Task' : 'Request Task')}
        </Button>
      );
    }
  };

  const handleExampleClick = (example) => {
    const parts = example.text.split(' - ', 2);
    if (parts.length === 2) {
      setTitle(parts[0].trim());
      setDescription(parts[1].trim());
    } else {
      setTitle(example.text.trim());
      setDescription('');
    }
    setTags([...tags, example.category]);
  };

  return (
    <form onSubmit={handleSubmit}>
      {!task && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Example Tasks</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
              {Object.entries(
                examples.tasks.reduce((acc, task) => {
                  if (!acc[task.category]) {
                    acc[task.category] = [];
                  }
                  acc[task.category].push(task);
                  return acc;
                }, {})
              ).map(([category, tasks]) => (
                <Accordion key={category}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>{category}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {tasks.map((example, index) => (
                        <ListItem key={index} button onClick={() => handleExampleClick(example)}>
                          <ListItemText 
                            primary={example.text.split(' - ', 2)[0]}
                            secondary={example.text.split(' - ', 2)[1] || ''}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      <TextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        multiline
        rows={4}
      />
      {role === 'dominant' && (
        <>
          <Typography>Points if completed:</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {POINT_PRESETS.map((point) => (
              <Button
                key={point}
                variant={pointsComplete === point ? "contained" : "outlined"}
                onClick={() => setPointsComplete(point)}
              >
                {point}
              </Button>
            ))}
          </Box>
          <Typography>Points if missed:</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {POINT_PRESETS.map((point) => (
              <Button
                key={point}
                variant={pointsIncomplete === point ? "contained" : "outlined"}
                onClick={() => setPointsIncomplete(point)}
              >
                {point}
              </Button>
            ))}
          </Box>
        </>
      )}
      <FormControlLabel
        control={
          <Switch
            checked={isRecurring}
            onChange={(e) => setIsRecurring(e.target.checked)}
          />
        }
        label="Recurring"
      />
      {isRecurring && (
        <>
          <FormControl fullWidth margin="normal">
            <InputLabel>Recurrence Pattern</InputLabel>
            <Select
              value={recurrencePattern}
              onChange={(e) => setRecurrencePattern(e.target.value)}
              label="Recurrence Pattern"
            >
              {recurrenceOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
      
          {(recurrencePattern === 'bi-weekly' || recurrencePattern === 'monthly') && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label={`Select First ${recurrencePattern === 'bi-weekly' ? 'Bi-weekly' : 'Monthly'} Date`}
                value={dueDate}
                onChange={(newValue) => setDueDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" required />}
              />
            </LocalizationProvider>
          )}

          {recurrencePattern === 'custom' && (
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Recurrence Type</InputLabel>
                <Select
                  value={customRecurrence.type}
                  onChange={(e) => setCustomRecurrence({
                    ...customRecurrence,
                    type: e.target.value,
                    weekDays: [],
                    monthDays: []
                  })}
                >
                  <MenuItem value="days">Days</MenuItem>
                  <MenuItem value="weeks">Weeks</MenuItem>
                  <MenuItem value="months">Months</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                type="number"
                label={`Every X ${customRecurrence.type}`}
                value={customRecurrence.interval}
                onChange={(e) => setCustomRecurrence({
                  ...customRecurrence,
                  interval: Math.max(1, parseInt(e.target.value) || 1)
                })}
                margin="normal"
                inputProps={{ min: 1 }}
              />

              {customRecurrence.type === 'weeks' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Week Days</InputLabel>
                  <Select
                    multiple
                    value={customRecurrence.weekDays}
                    onChange={(e) => setCustomRecurrence({
                      ...customRecurrence,
                      weekDays: e.target.value
                    })}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {weekDays.map(day => (
                      <MenuItem key={day} value={day}>
                        <Checkbox checked={customRecurrence.weekDays.indexOf(day) > -1} />
                        <ListItemText primary={day.charAt(0).toUpperCase() + day.slice(1)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {customRecurrence.type === 'months' && (
                <>
                  <TextField
                    fullWidth
                    type="number"
                    label="Every X Months"
                    value={customRecurrence.interval}
                    onChange={(e) => setCustomRecurrence({
                      ...customRecurrence,
                      interval: Math.max(1, parseInt(e.target.value) || 1)
                    })}
                    margin="normal"
                    inputProps={{ min: 1 }}
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Select First Date"
                      value={dueDate}
                      onChange={(newValue) => setDueDate(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth margin="normal" required />
                      )}
                    />
                  </LocalizationProvider>
                </>
              )}
            </Box>
          )}

          {(recurrencePattern === 'weekly' || recurrencePattern === 'bi-weekly') && (
            <FormControl fullWidth margin="normal">
              <InputLabel>Week Day</InputLabel>
              <Select
                value={weekDay}
                onChange={(e) => setWeekDay(e.target.value)}
                label="Week Day"
              >
                {weekDays.map(day => (
                  <MenuItem key={day} value={day}>
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
      {!isRecurring && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Due Date"
            value={dueDate}
            onChange={(newValue) => setDueDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" required />}
          />
        </LocalizationProvider>
      )}
      {role === 'dominant' && (
        <>
          <FormControlLabel
            control={
              <Switch
                checked={requireProof}
                onChange={(e) => setRequireProof(e.target.checked)}
                name="require_proof"
              />
            }
            label="Requires Proof"
          />
          <FormControlLabel
            control={
              <Switch
                checked={gpsCheckIn}
                onChange={(e) => setGpsCheckIn(e.target.checked)}
                name="gps_check_in"
              />
            }
            label="Require GPS Check-in"
          />
          <FormControlLabel
            control={
              <Switch
                checked={sendNotification}
                onChange={(e) => setSendNotification(e.target.checked)}
                name="send_notification"
              />
            }
            label="Send Notification if Not Completed"
          />
          <FormControlLabel
            control={
              <Switch
                checked={submissiveCanComplete}
                onChange={(e) => setSubmissiveCanComplete(e.target.checked)}
                name="submissive_can_complete"
              />
            }
            label="Submissive Can Complete Independently"
          />
        </>
      )}
      <TextField
        fullWidth
        label="Tags"
        value={tagInput}
        onChange={handleTagInputChange}
        margin="normal"
        helperText="Type a tag and press space to add it"
        name="tags"
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => setTags(tags.filter((t, i) => i !== index))}
            color="primary"
            variant="outlined"
          />
        ))}
      </Box>
      {renderActionButtons()}
    </form>
  );
};

const TaskListItem = ({ task, role, onEdit, onDelete, onAssign, onApprove, onDeny, onComplete, getTaskStatusColor, showDate }) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => setExpanded(!expanded);

  return (
    <ListItem
      alignItems="flex-start"
      disablePadding
      sx={{ borderBottom: 1, borderColor: 'divider', py: 1 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              onClick={handleToggle}
            >
              {task.title}
              {showDate && (
                <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                  {new Date(task.due_date).toLocaleTimeString()}
                </Typography>
              )}
            </Typography>
            {task.is_recurring && <CompletionDots completionHistory={task.completion_history} />}
          </Box>
          {task.gps_check_in && (
            <Tooltip title="GPS Check-in Required">
              <LocationOn fontSize="small" color="primary" id="gps-indicator" />
            </Tooltip>
          )}
          {task.is_recurring && (
            <Tooltip title="Recurring Task">
              <AutorenewRounded fontSize="small" color="primary" id="recurring-tasks" />
            </Tooltip>
          )}
          {task.submissive_can_complete && (
            <Tooltip title="Submissive Can Complete">
              <CheckCircleOutline fontSize="small" color="primary" id="auto-complete-indicator" />
            </Tooltip>
          )}
          {role === 'dominant' && (
            <>
              {task.status === 'pending_approval' ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onEdit(task)}
                  sx={{ mr: 1 }}
                >
                  Review
                </Button>
              ) : (
                <>
                  <IconButton size="small" onClick={() => onEdit(task)}><EditIcon fontSize="small" /></IconButton>
                  <IconButton size="small" onClick={() => onDelete(task.id)}><DeleteIcon fontSize="small" /></IconButton>
                  {!task.assigned && (
                    <IconButton size="small" onClick={() => onAssign(task.id)}><AssignIcon fontSize="small" /></IconButton>
                  )}
                </>
              )}
            </>
          )}
          <IconButton size="small" onClick={handleToggle}>
            {expanded ? <ExpandLessIcon /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ py: 1, px: 1 }}>
            <Typography variant="body2">{task.description}</Typography>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2">Due: {new Date(task.due_date).toLocaleString()}</Typography>
              <Typography variant="body2">
                Status: <span style={{ color: getTaskStatusColor(task.status) }}>{task.status}</span>
              </Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              {task.tags && task.tags.map((tag, index) => (
                <Chip key={index} label={tag} size="small" sx={{ mr: 0.5, mt: 0.5 }} />
              ))}
            </Box>
            {role === 'submissive' && (task.status === 'incomplete' || task.submissive_can_complete) && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => onComplete(task)}
                sx={{ mt: 1 }}
                className="complete-task-button"
              >
                Complete
              </Button>
            )}
          </Box>
        </Collapse>
      </Box>
    </ListItem>
  );
};

const CompletionForm = ({ show, handleClose, handleSubmit, item, itemType, requireProof }) => {
  const [proof, setProof] = useState(null);
  const [note, setNote] = useState('');
  const [enjoymentRating, setEnjoymentRating] = useState(0);
  const [difficultyRating, setDifficultyRating] = useState(0);
  const [humiliationRating, setHumiliationRating] = useState(0);
  const [timerDuration, setTimerDuration] = useState(0);
  const [location, setLocation] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', item.id);
    formData.append('type', itemType);
    if (proof) formData.append('proof', proof);
    const finalNote = item.tags && item.tags.includes('exercise')
      ? `${note}\nExercise duration: ${formatTime(timerDuration)}`
      : note;
    formData.append('note', finalNote);
    formData.append('enjoyment_rating', enjoymentRating);
    formData.append('difficulty_rating', difficultyRating);
    formData.append('humiliation_rating', humiliationRating);
    if (item.tags && item.tags.includes('exercise') && timerDuration > 0) {
      formData.append('timer_duration', timerDuration);
    }
    if (location) {
      formData.append('latitude', location.latitude);
      formData.append('longitude', location.longitude);
    }
    handleSubmit(formData);
  };

  useEffect(() => {
    if (item && item.gps_check_in) {
      navigator.serviceWorker.controller.postMessage({ type: 'GET_LOCATION' });
  
      const handleMessage = (event) => {
        if (event.data && event.data.type === 'LOCATION') {
          setLocation(event.data);
        } else if (event.data && event.data.type === 'LOCATION_ERROR') {
          console.error('Error getting location:', event.data.error);
        }
      };
  
      navigator.serviceWorker.addEventListener('message', handleMessage);
  
      return () => {
        navigator.serviceWorker.removeEventListener('message', handleMessage);
      };
    }
  }, [item]);


  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>Submit Completion</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          {requireProof && (
            <TextField
              type="file"
              accept="image/*,video/*"
              onChange={(e) => setProof(e.target.files[0])}
              fullWidth
              margin="normal"
              required
            />
          )}
          {item && item.tags && item.tags.includes('exercise') && (
            <Timer onTimerUpdate={setTimerDuration} />
          )}
          <TextField
            label="Note"
            multiline
            rows={3}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            fullWidth
            margin="normal"
          />
          {item && item.gps_check_in && (
            <Box mt={2}>
              <Typography>GPS Location:</Typography>
              {location ? (
                <Typography>
                  Latitude: {location.latitude}, Longitude: {location.longitude}
                </Typography>
              ) : (
                <Typography>Fetching location...</Typography>
              )}
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Typography>Enjoyment:</Typography>
            <Rating
              name="enjoyment"
              value={enjoymentRating}
              onChange={(event, newValue) => setEnjoymentRating(newValue)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Difficulty:</Typography>
            <Rating
              name="difficulty"
              value={difficultyRating}
              onChange={(event, newValue) => setDifficultyRating(newValue)}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>Humiliation:</Typography>
            <Rating
              name="humiliation"
              value={humiliationRating}
              onChange={(event, newValue) => setHumiliationRating(newValue)}
            />
          </Box>
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Submit Completion
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Tasks = ({ role, kinks }) => {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [sortBy, setSortBy] = useState('dueGroups');
  const [groupBy, setGroupBy] = useState('dueDate');
  const [tags, setTags] = useState([]);

  const fetchTasks = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/tasks`);
      console.log('Fetched tasks:', response.data);
      setTasks(response.data.map(task => ({
        ...task,
        due_date: task.due_date ? new Date(task.due_date) : null,
        tags: task.tags || []
      })));
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setErrorMessage('Failed to fetch tasks. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    const allTags = [...new Set(tasks.flatMap(task => task.tags || []))];
    setTags(allTags);
  }, [tasks]);

  const handleDeleteTask = async (taskId) => {
    try {
      await axiosInstance.delete(`/tasks/${taskId}`);
      fetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error);
      setErrorMessage('Failed to delete task. Please try again.');
    }
  };

  const handleCreateOrEditTask = async (taskData) => {
    try {
      if (taskData.id) {
        await axiosInstance.put(`/tasks/${taskData.id}`, taskData);
      } else {
        await axiosInstance.post(`/tasks`, taskData);
      }
      fetchTasks();
      setShowTaskModal(false);
      setSelectedItem(null);
    } catch (error) {
      console.error('Error creating/editing task:', error);
      setErrorMessage('Failed to create/edit task. Please try again.');
    }
  };

  const handleAssignTask = async (taskId) => {
    try {
      await axiosInstance.post(`/tasks/${taskId}/assign`);
      fetchTasks();
    } catch (error) {
      console.error('Error assigning task:', error);
      setErrorMessage('Failed to assign task. Please try again.');
    }
  };

  const handleCompleteTask = (task) => {
    setSelectedItem({...task, itemType: 'task'});
    setShowCompletionModal(true);
  };

  const handleApproveTask = async (taskId) => {
    try {
      await axiosInstance.post(`/tasks/${taskId}/approve`);
      fetchTasks();
      setShowTaskModal(false);
      setErrorMessage('Task approved successfully');
    } catch (error) {
      console.error('Error approving task:', error);
      setErrorMessage('Failed to approve task. Please try again.');
    }
  };

  const handleDenyTask = async (taskId) => {
    try {
      await axiosInstance.post(`/tasks/${taskId}/deny`);
      fetchTasks();
      setShowTaskModal(false);
      setErrorMessage('Task denied successfully');
    } catch (error) {
      console.error('Error denying task:', error);
      setErrorMessage('Failed to deny task. Please try again.');
    }
  };

  const handleSubmitCompletion = async (formData) => {
    try {
      await axiosInstance.post(`/completions`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      await fetchTasks();
      setShowCompletionModal(false);
      setSelectedItem(null);
      setErrorMessage('Task completion submitted successfully');
    } catch (error) {
      console.error('Error completing task:', error);
      setErrorMessage('Failed to complete task. Please try again.');
    }
  };

  const getNextDueDate = (task) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    if (task.is_recurring) {
      switch (task.recurrence_pattern) {
        case 'daily':
          return new Date(today.getTime() + 24 * 60 * 60 * 1000);
        case 'weekly':
        case 'bi-weekly':
          const daysUntilWeekDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(task.week_day.toLowerCase()) - today.getDay();
          const daysToAdd = daysUntilWeekDay >= 0 ? daysUntilWeekDay : daysUntilWeekDay + 7;
          const nextDate = new Date(today.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
          if (task.recurrence_pattern === 'bi-weekly' && daysToAdd < 7) {
            nextDate.setDate(nextDate.getDate() + 7);
          }
          return nextDate;
        case 'monthly':
          const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          return nextMonth;
        default:
          return task.due_date;
      }
    } else {
      return task.due_date;
    }
  };

  const sortTasks = (taskList) => {
    return [...taskList].sort((a, b) => {
      if (sortBy === 'name') {
        return a.title.localeCompare(b.title);
      } else if (sortBy === 'dueDate') {
        const dateA = getNextDueDate(a);
        const dateB = getNextDueDate(b);
        return dateA - dateB;
      }
      return 0;
    });
  };

  const groupTasks = (tasks) => {
    if (groupBy === 'dueDate') {
      const now = new Date();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
      const endOfWeek = new Date(now.getTime() + (6 - now.getDay()) * 24 * 60 * 60 * 1000);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  
      return {
        'Due Today': tasks.filter(task => getNextDueDate(task) <= endOfDay),
        'Due This Week': tasks.filter(task => {
          const dueDate = getNextDueDate(task);
          return dueDate > endOfDay && dueDate <= endOfWeek;
        }),
        'Due This Month': tasks.filter(task => {
          const dueDate = getNextDueDate(task);
          return dueDate > endOfWeek && dueDate <= endOfMonth;
        }),
        'Due Later': tasks.filter(task => getNextDueDate(task) > endOfMonth)
      };
    } else if (groupBy === 'tag') {
      return tasks.reduce((acc, task) => {
        task.tags.forEach(tag => {
          if (!acc[tag]) acc[tag] = [];
          acc[tag].push(task);
        });
        return acc;
      }, {});
    } else if (groupBy === 'status') {
      return tasks.reduce((acc, task) => {
        if (!acc[task.status]) acc[task.status] = [];
        acc[task.status].push(task);
        return acc;
      }, {});
    }
    return { 'All Tasks': tasks };
  };

  const filterTasks = (tasks, status) => {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
    const endOfWeek = new Date(now.getTime() + (6 - now.getDay()) * 24 * 60 * 60 * 1000);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    return tasks.filter(task => {
      if (task.status === 'pending_approval') return false;
      const dueDate = getNextDueDate(task);
      switch (status) {
        case 'dueToday':
          return dueDate <= endOfDay;
        case 'dueThisWeek':
          return dueDate > endOfDay && dueDate <= endOfWeek;
        case 'dueThisMonth':
          return dueDate > endOfWeek && dueDate <= endOfMonth;
        default:
          return true;
      }
    });
  };

  if (isLoading) return <CircularProgress />;

const pendingApprovalTasks = tasks.filter(task => task.status === 'pending_approval');
  const dueTodayTasks = filterTasks(tasks, 'dueToday');
  const dueThisWeekTasks = filterTasks(tasks, 'dueThisWeek');
  const dueThisMonthTasks = filterTasks(tasks, 'dueThisMonth');

const renderTaskList = (taskList, showDate = false) => (
    <List>
      {sortTasks(taskList).map(task => (
        <TaskListItem
          key={task.id}
          task={task}
          role={role}
          showDate={showDate}
          onEdit={(task) => {
            setSelectedItem(task);
            setShowTaskModal(true);
          }}
          onDelete={handleDeleteTask}
          onAssign={handleAssignTask}
          onApprove={handleApproveTask}
          onDeny={handleDenyTask}
          onComplete={handleCompleteTask}
          getTaskStatusColor={getTaskStatusColor}
        />
      ))}
    </List>
  );

  const renderGroupedTasks = () => {
   const groupedTasks = groupTasks(tasks);
   return Object.entries(groupedTasks).map(([group, tasks]) => (
     <Accordion key={group}>
       <AccordionSummary expandIcon={<ExpandMore />}>
         <Typography variant="h6">{group}</Typography>
       </AccordionSummary>
       <AccordionDetails>
         {renderTaskList(tasks)}
       </AccordionDetails>
     </Accordion>
   ));
 };

  const renderDueGroups = () => {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
    const endOfWeek = new Date(now.getTime() + (6 - now.getDay()) * 24 * 60 * 60 * 1000);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const dueTodayTasks = tasks.filter(task => {
      const dueDate = getNextDueDate(task);
      return dueDate <= endOfDay && task.status !== 'pending_approval';
    });

    const dueThisWeekTasks = tasks.filter(task => {
      const dueDate = getNextDueDate(task);
      return dueDate > endOfDay && dueDate <= endOfWeek && task.status !== 'pending_approval';
    });

    const dueThisMonthTasks = tasks.filter(task => {
      const dueDate = getNextDueDate(task);
      return dueDate > endOfWeek && dueDate <= endOfMonth && task.status !== 'pending_approval';
    });

    const dueLaterTasks = tasks.filter(task => {
      const dueDate = getNextDueDate(task);
      return dueDate > endOfMonth && task.status !== 'pending_approval';
    });
  
    const groups = [
      { title: "Due Today", tasks: dueTodayTasks },
      { title: "Due This Week", tasks: dueThisWeekTasks },
      { title: "Due This Month", tasks: dueThisMonthTasks },
      { title: "Due Later", tasks: dueLaterTasks },
    ];
  
    return (
      <>
        {role === 'dominant' && pendingApprovalTasks.length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">Pending Approval</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderTaskList(pendingApprovalTasks)}
            </AccordionDetails>
          </Accordion>
        )}
        {groups.map(group => (
          group.tasks.length > 0 && (
            <Accordion key={group.title} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">{group.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderTaskList(group.tasks)}
              </AccordionDetails>
            </Accordion>
          )
        ))}
      </>
    );
  };

  const renderSortedTasks = () => {
    if (sortBy === 'dueDate') {
      // Group tasks by due date
      const tasksByDate = tasks.reduce((acc, task) => {
        const dueDate = getNextDueDate(task);
        const dateStr = dueDate.toLocaleDateString();
        if (!acc[dateStr]) acc[dateStr] = [];
        acc[dateStr].push(task);
        return acc;
      }, {});
  
      return Object.entries(tasksByDate)
        .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
        .map(([date, dateTasks]) => (
          <Box key={date} sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ py: 1 }}>{date}</Typography>
            {renderTaskList(dateTasks, sortBy === 'DueDate')}
          </Box>
        ));
    } else if (sortBy === 'alphabetical') {
      return renderTaskList(tasks);
    } else if (sortBy === 'tag') {
      return tags.map(tag => (
        <Accordion key={tag} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{tag}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderTaskList(tasks.filter(task => task.tags.includes(tag)))}
          </AccordionDetails>
        </Accordion>
      ));
    }
  };

  return (
    <Container maxWidth={false} sx={{ px: '20px' }}>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Sort By</InputLabel>
        <Select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          label="Sort By"
        >
          <MenuItem value="dueGroups">Due Groups</MenuItem>
          <MenuItem value="dueDate">Due Date</MenuItem>
          <MenuItem value="tag">Tag</MenuItem>
          <MenuItem value="alphabetical">Alphabetical</MenuItem>
        </Select>
      </FormControl>

      {tasks.length === 0 && (
        <Typography variant="body1">No tasks available, get started by adding one or check out the examples.</Typography>
      )}

      {sortBy === 'dueGroups' ? renderDueGroups() : renderSortedTasks()}

      <Dialog
        open={showTaskModal}
        onClose={() => {
          setShowTaskModal(false);
          setSelectedItem(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedItem
            ? selectedItem.status === 'pending_approval'
              ? 'Review Task Request'
              : 'Edit Task'
            : role === 'dominant'
            ? 'Create New Task'
            : 'Request New Task'}
        </DialogTitle>
        <DialogContent>
          <TaskForm
            onSubmit={handleCreateOrEditTask}
            onClose={() => {
              setShowTaskModal(false);
              setSelectedItem(null);
            }}
            task={selectedItem}
            role={role}
            kinks={kinks}
            onApprove={handleApproveTask}
            onDeny={handleDenyTask}
          />
        </DialogContent>
      </Dialog>

      <CompletionForm
        show={showCompletionModal}
        handleClose={() => {
          setShowCompletionModal(false);
          setSelectedItem(null);
        }}
        handleSubmit={handleSubmitCompletion}
        item={selectedItem}
        itemType={selectedItem?.itemType}
        requireProof={selectedItem?.require_proof}
      />

      <Fab 
        color="primary" 
        aria-label="add" 
        sx={{ position: 'fixed', bottom: 70, right: 16 }} 
        onClick={() => setShowTaskModal(true)}
        id="create-task-button"
      >
        <Add />
      </Fab>
    </Container>
  );
};

export default Tasks;

export {
  CompletionForm,
  TaskForm,
};