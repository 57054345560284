import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Tabs, Tab, Box } from '@mui/material';
import Rules from './rules';
import Kinks from './kinks';
import Toys from './toys';
import Surveys from './surveys';
import Settings from './settings';
import SpecialDays from './SpecialDays';

const RulesKinksAndToys = ({ role, currentTheme, setCurrentTheme, submissiveTheme, setSubmissiveTheme, welcomeMessage, setWelcomeMessage }) => {
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    switch (hash) {
      case 'rules':
        setTabValue(0);
        break;
      case 'kinks':
        setTabValue(1);
        break;
      case 'special-days':
        setTabValue(2);
        break;
      case 'toys':
        setTabValue(3);
        break;
      case 'surveys':
        setTabValue(4);
        break;
      case 'settings':
        setTabValue(5);
        break;
      default:
        setTabValue(0);
    }
  }, [location]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ px: '0px', overflowX: 'hidden' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto', maxWidth: '100%' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          sx={{ width: '100%' }}
        >
          <Tab label="Rules" />
          <Tab label="Kinks" />
          <Tab label="Special Days" />
          <Tab label="Toys" />
          <Tab label="Surveys" />
          {role === 'dominant' && <Tab label="Settings" />}
        </Tabs>
      </Box>
      <Box sx={{ maxWidth: '100%', overflowX: 'hidden', px: { xs: 1, sm: 2, md: 3 } }}>
        {tabValue === 0 && <Rules role={role} />}
        {tabValue === 1 && <Kinks role={role} />}
        {tabValue === 2 && <SpecialDays role={role} />}
        {tabValue === 3 && <Toys role={role} />}
        {tabValue === 4 && <Surveys role={role} />}
        {tabValue === 5 && (
          <Settings
            role={role}
            currentTheme={currentTheme}
            setCurrentTheme={setCurrentTheme}
            submissiveTheme={submissiveTheme}
            setSubmissiveTheme={setSubmissiveTheme}
            welcomeMessage={welcomeMessage}
            setWelcomeMessage={setWelcomeMessage}
          />
        )}
      </Box>
    </Container>
  );
};

export default RulesKinksAndToys;