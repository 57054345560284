import React, { useState, useEffect } from 'react';
import { axiosInstance } from './auth';
import { useNavigate } from 'react-router-dom';
import { 
  IconButton, 
  Badge, 
  Menu, 
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Typography
} from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';

const NotificationBell = ({ role }) => {
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axiosInstance.get(`/notifications`);
        setNotifications(response.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000); // Fetch every minute

    return () => clearInterval(intervalId);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification) => {
    if (notification.link) {
      navigate(notification.link);
      handleClose();
    }
  };

  const handleClearNotifications = async () => {
    try {
      await axiosInstance.post('/clear-notifications');
      setNotifications([]);
    } catch (error) {
      console.error('Error clearing notifications:', error);
    }
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={notifications.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '80vh',
            width: '300px',
          },
        }}
      >
        {notifications.length > 0 ? (
          <>
            <List>
              {notifications.map((notification, index) => (
                <ListItem 
                  key={index} 
                  onClick={() => handleNotificationClick(notification)}
                  button={!!notification.link}
                >
                  <ListItemText 
                    primary={notification.message}
                    secondary={new Date(notification.timestamp).toLocaleString()}
                  />
                </ListItem>
              ))}
            </List>
            <Box textAlign="center" mt={2} mb={1}>
              <Button onClick={handleClearNotifications} color="primary">
                Clear Notifications
              </Button>
            </Box>
          </>
        ) : (
          <Box textAlign="center" py={2}>
            <Typography variant="body1">All caught up</Typography>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default NotificationBell;