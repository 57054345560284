// Settings.js
import React, { useState, useEffect, createContext, useContext } from 'react';
import { Box, Typography, Select, MenuItem, Button, TextField, CircularProgress, Snackbar, Grid } from '@mui/material';
import { 
  updateUserTheme, 
  updateSubmissiveTheme, 
  getThemeNames, 
  fetchUserTheme, 
  fetchSubmissiveTheme 
} from './theme';
import { updateWelcomeMessage } from './welcomeMessage';
import { axiosInstance, isAuthenticated } from './auth';

const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    chastityEnabled: false,
    isPermanentChastity: false,
    userTheme: 'light',
    partnerTheme: 'light',
  });

useEffect(() => {
  const fetchSettings = async () => {
    try {
      const response = await axiosInstance.get('/settings');
      setSettings(response.data);
    } catch (error) {
      console.error('Failed to fetch settings:', error);
    }
  };

  if (isAuthenticated()) {
    fetchSettings();
  }
}, [isAuthenticated]);

  const updateSettings = (newSettings) => {
    setSettings(prevSettings => ({ ...prevSettings, ...newSettings }));
  };

  return (
    <SettingsContext.Provider value={{ settings, updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

const Settings = ({ role, currentTheme, setCurrentTheme, submissiveTheme, setSubmissiveTheme, welcomeMessage, setWelcomeMessage }) => {
  const { settings, updateSettings } = useSettings();
  const [newWelcomeMessage, setNewWelcomeMessage] = useState(welcomeMessage);
  const [timezone, setTimezone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [userProfile, setUserProfile] = useState({});
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const themeNames = getThemeNames();
  const [showOtherGender, setShowOtherGender] = useState(false);

  useEffect(() => {
    fetchUserProfile();
    fetchCurrentTimezone();
  }, []);

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const userTheme = await fetchUserTheme();
        const partnerTheme = role === 'dominant' ? await fetchSubmissiveTheme() : null;
        
        updateSettings({
          userTheme: userTheme,
          partnerTheme: partnerTheme
        });
      } catch (error) {
        console.error('Error fetching themes:', error);
        setError('Failed to fetch themes');
      }
    };
  
    fetchThemes();
  }, [role]);

  const fetchUserProfile = async () => {
    try {
      const response = await axiosInstance.get('/user-profile');
      setUserProfile(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Failed to fetch user profile');
    }
  };

  const fetchCurrentTimezone = async () => {
    try {
      const response = await axiosInstance.get('/timezone');
      setTimezone(response.data.timezone);
    } catch (error) {
      console.error('Error fetching current timezone:', error);
      setError('Failed to fetch current timezone');
    }
  };

  const handleProfileUpdate = async (field, value) => {
    try {
      await axiosInstance.put('/user-profile', { [field]: value });
      setUserProfile({ ...userProfile, [field]: value });
      setSuccess('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await axiosInstance.put('/change-password', { newPassword });
      setSuccess('Password changed successfully');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      setError('Failed to change password');
    }
  };

  const handleThemeChange = async (newTheme, isPartner = false) => {
    try {
      console.log(`Attempting to change ${isPartner ? 'partner' : 'user'} theme to ${newTheme}`);
      const endpoint = isPartner ? '/partner-theme' : '/user-theme';
      const response = await axiosInstance.post(endpoint, { theme: newTheme });
      console.log('Server response:', response.data);
  
      updateSettings({ [isPartner ? 'partnerTheme' : 'userTheme']: newTheme });
      
      if (isPartner) {
        if (typeof setSubmissiveTheme === 'function') {
          setSubmissiveTheme(newTheme);
        } else {
          console.error('setSubmissiveTheme is not a function', setSubmissiveTheme);
        }
      } else {
        if (typeof setCurrentTheme === 'function') {
          setCurrentTheme(newTheme);
        } else {
          console.error('setCurrentTheme is not a function', setCurrentTheme);
        }
      }
      
      setSuccess(`${isPartner ? 'Partner' : 'User'} theme updated successfully`);
    } catch (error) {
      console.error('Error updating theme:', error);
      setError(`Failed to update ${isPartner ? 'partner' : 'user'} theme`);
    }
  };
  
  const handleWelcomeMessageChange = async () => {
    try {
      await updateWelcomeMessage(newWelcomeMessage);
      setWelcomeMessage(newWelcomeMessage);
      setSuccess('Welcome message updated successfully');
    } catch (error) {
      console.error('Error updating welcome message:', error);
      setError('Failed to update welcome message');
    }
  };

  const handleClearData = async () => {
    if (window.confirm("Are you sure you want to clear all data? This action cannot be undone.")) {
      try {
        await axiosInstance.post('/clear-database');
        setSuccess('Database cleared successfully');
        window.location.reload();
      } catch (error) {
        console.error('Error clearing data:', error);
        setError('Failed to clear data');
      }
    }
  };

  const handleTimezoneChange = async (event) => {
    const newTimezone = event.target.value;
    try {
      await axiosInstance.post('/timezone', { timezone: newTimezone });
      setTimezone(newTimezone);
      setSuccess('Timezone updated successfully');
    } catch (error) {
      console.error('Error updating timezone:', error);
      setError('Failed to update timezone');
    }
  };

  const handleSimulateNewDay = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post('/simulate-day-change');
      console.log('Simulation response:', response.data);
      setSuccess('New day simulated successfully');
    } catch (error) {
      console.error('Error simulating new day:', error);
      setError('Failed to simulate new day');
    } finally {
      setIsLoading(false);
    }
  };

const handleChastityToggle = async () => {
  try {
    const response = await axiosInstance.put('/settings', {
      chastityEnabled: !settings.chastityEnabled
    });
    updateSettings(response.data);
    window.dispatchEvent(new Event('settingsChanged'));
    setSuccess('Chastity setting updated successfully');
  } catch (error) {
    console.error('Error updating chastity setting:', error);
    setError('Failed to update chastity setting');
  }
};

const handlePermanentChastityToggle = async () => {
  try {
    const response = await axiosInstance.put('/settings', {
      isPermanentChastity: !settings.isPermanentChastity
    });
    updateSettings(response.data);
    setSuccess('Permanent chastity setting updated successfully');
  } catch (error) {
    console.error('Error updating permanent chastity setting:', error);
    setError('Failed to update permanent chastity setting');
  }
};

const handleGenderChange = (event) => {
  const value = event.target.value;
  if (value === 'other') {
    setShowOtherGender(true);
    handleProfileUpdate('gender', '');
  } else {
    setShowOtherGender(false);
    handleProfileUpdate('gender', value);
  }
};

const handleOtherGenderChange = (event) => {
  handleProfileUpdate('gender', event.target.value);
};

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Settings</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" gutterBottom>User Profile</Typography>
          <TextField
            fullWidth
            label="Username"
            value={userProfile.username || ''}
            onChange={(e) => handleProfileUpdate('username', e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            value={userProfile.email || ''}
            onChange={(e) => handleProfileUpdate('email', e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Pronouns"
            value={userProfile.pronouns || ''}
            onChange={(e) => handleProfileUpdate('pronouns', e.target.value)}
            margin="normal"
          />
          <Select
            fullWidth
            value={showOtherGender ? 'other' : (userProfile.gender || '')}
            onChange={handleGenderChange}
            margin="normal"
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="nonbinary">Nonbinary</MenuItem>
            <MenuItem value="genderfluid">Genderfluid</MenuItem>
            <MenuItem value="other">Other: Specify</MenuItem>
            <MenuItem value="rather_not_say">Rather Not Say</MenuItem>
          </Select>
          {showOtherGender && (
            <TextField
              fullWidth
              value={userProfile.gender || ''}
              onChange={handleOtherGenderChange}
              placeholder="Specify your gender"
              margin="normal"
            />
          )}
          <Select
            fullWidth
            value={userProfile.sex || ''}
            onChange={(e) => handleProfileUpdate('sex', e.target.value)}
            margin="normal"
          >
            <MenuItem value="afab">AFAB</MenuItem>
            <MenuItem value="amab">AMAB</MenuItem>
            <MenuItem value="MTF">MTF</MenuItem>
            <MenuItem value="FTM">FTM</MenuItem>
            <MenuItem value="intersex">Intersex</MenuItem>
            <MenuItem value="indeterminate">Indeterminate</MenuItem>
          </Select>
          <Select
            fullWidth
            value={userProfile.user_type || ''}
            onChange={(e) => handleProfileUpdate('user_type', e.target.value)}
            margin="normal"
          >
            <MenuItem value="dominant">Dominant</MenuItem>
            <MenuItem value="submissive">Submissive</MenuItem>
          </Select>
          <TextField
            fullWidth
            type="password"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            type="password"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="normal"
          />
          <Button onClick={handlePasswordChange} variant="contained" color="primary">
            Change Password
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          {role === 'dominant' && (
            <>
              <Typography variant="subtitle1" gutterBottom>Dominant Settings</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2">Your Theme:</Typography>
                  <Select
                    fullWidth
                    value={settings.userTheme}
                    onChange={(e) => handleThemeChange(e.target.value)}
                    margin="normal"
                  >
                    {themeNames.map((theme) => (
                      <MenuItem key={theme} value={theme}>{theme}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Submissive's Theme:</Typography>
                  <Select
                    fullWidth
                    value={settings.partnerTheme}
                    onChange={(e) => handleThemeChange(e.target.value, true)}
                    margin="normal"
                  >
                    {themeNames.map((theme) => (
                      <MenuItem key={theme} value={theme}>{theme}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Welcome Message"
                    value={newWelcomeMessage}
                    onChange={(e) => setNewWelcomeMessage(e.target.value)}
                    margin="normal"
                    multiline
                    rows={2}
                  />
                  <Button 
                    onClick={handleWelcomeMessageChange} 
                    variant="contained" 
                    color="primary"
                    sx={{ mt: 1, mb: 2 }}
                  >
                    Update Welcome Message
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" gutterBottom>Timezone:</Typography>
                  <Select
                    fullWidth
                    value={timezone}
                    onChange={handleTimezoneChange}
                    margin="normal"
                  >
                    {Intl.supportedValuesOf('timeZone').map((tz) => (
                      <MenuItem key={tz} value={tz}>{tz}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Button 
                    onClick={handleSimulateNewDay} 
                    variant="contained" 
                    color="primary" 
                    disabled={isLoading}
                    sx={{ mr: 1, mb: 1 }}
                  >
                    {isLoading ? <CircularProgress size={24} /> : 'Simulate New Day'}
                  </Button>
                  <Button 
                    onClick={handleClearData} 
                    variant="contained" 
                    color="error"
                    sx={{ mr: 1, mb: 1 }}
                  >
                    Clear All Data
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button 
                    onClick={handleChastityToggle} 
                    variant="contained" 
                    color="primary"
                    sx={{ mr: 1, mb: 1 }}
                  >
                    {settings.chastityEnabled ? 'Disable Chastity' : 'Enable Chastity'}
                  </Button>
                  <Button 
                    onClick={handlePermanentChastityToggle} 
                    variant="contained" 
                    color="primary" 
                    disabled={!settings.chastityEnabled}
                    sx={{ mb: 1 }}
                  >
                    {settings.isPermanentChastity ? 'Disable Permanent Chastity' : 'Enable Permanent Chastity'}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={!!error || !!success}
        autoHideDuration={6000}
        onClose={() => {
          setError('');
          setSuccess('');
        }}
        message={error || success}
      />
    </Box>
  );
};

export default Settings;